<template>
  <div class="flex items-center">
    <input
      type="checkbox"
      :value="props.value"
      :id="fieldId"
      v-model="isChecked"
      @change="$emit('update:checked', { isChecked, value: props.value })"
      class="mr-2 checkbox"
    />
    <label :for="fieldId" class="flex text-left flex-row items-center cursor-pointer select-none">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits } from 'vue'

defineEmits(['update:checked'])

const props = defineProps({
  checked: {
    type: null,
    default: false
  },
  value: {
    type: String,
    default: null
  },
  fieldId: {
    type: String,
    required: true
  },
  label: String
})

const isChecked = ref(props.checked)
</script>

<style scoped>
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #2f7f91;
  display: flex;
  margin-right: 4px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
}

input[type='checkbox']::after {
  appearance: none;
  content: ' ';
  height: 100%;
  width: 100%;
  background-color: #2f7f91;
  background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IndoaXRlIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PCEtLSEgRm9udCBBd2Vzb21lIFBybyA2LjIuMCBieSBAZm9udGF3ZXNvbWUgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbSBMaWNlbnNlIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20vbGljZW5zZSAoQ29tbWVyY2lhbCBMaWNlbnNlKSBDb3B5cmlnaHQgMjAyMiBGb250aWNvbnMsIEluYy4gLS0+PHBhdGggZD0iTTQ3MC42IDEwNS40YzEyLjUgMTIuNSAxMi41IDMyLjggMCA0NS4zbC0yNTYgMjU2Yy0xMi41IDEyLjUtMzIuOCAxMi41LTQ1LjMgMGwtMTI4LTEyOGMtMTIuNS0xMi41LTEyLjUtMzIuOCAwLTQ1LjNzMzIuOC0xMi41IDQ1LjMgMEwxOTIgMzM4LjcgNDI1LjQgMTA1LjRjMTIuNS0xMi41IDMyLjgtMTIuNSA0NS4zIDB6Ii8+PC9zdmc+');
  background-size: contain;
  display: none;
}

input[type='checkbox']:checked:after {
  display: block;
}

label {
  cursor: pointer;
  word-break: normal;
  user-select: none;
}
</style>
