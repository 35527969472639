import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CreateDoctorRequest, ResponseCompanyDoctorInvite } from '../Types/Requests/DoctorRequests'
export type MobileStoresUrls = {
  androidUrl: string
  iosUrl: string
}
export default class DoctorService extends Service {
  prefix: string
  constructor() {
    super()
    this.prefix = '/doctors'
  }

  async create(payload: CreateDoctorRequest): Promise<MobileStoresUrls> {
    const { data } = await request.post<MobileStoresUrls>(this.getCompletePath(''), payload)
    return data
  }

  async sendResponseToCompanyDoctorInvite(
    payload: ResponseCompanyDoctorInvite
  ): Promise<MobileStoresUrls> {
    const { data } = await request.post<MobileStoresUrls>(
      this.getCompletePath('/invite-accept'),
      payload
    )
    return data
  }
}
