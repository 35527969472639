<template>
  <div class="w-full">
    <v-autocomplete
      @update:modelValue="$emit('onSelect', $event)"
      :model-value="modelValue"
      :items="items"
      :disabled="!scheduleId"
      :variant="variant"
      class="w-full"
      color="primary20"
      label="Posição"
      theme="light"
      hide-details
    >
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.title" :subtitle="item?.raw?.subtitle">
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script lang="ts">
import { computed, onMounted, reactive } from 'vue'
import Network from '@/Network'
import { PositionResponse } from '@/Network/Types/Responses/PositionResponses'
export default {
  name: 'SelectPositions',
  props: {
    scheduleId: { required: true, type: Number },
    variant: { required: false, type: String, default: 'outlined' },
    modelValue: { required: false }
  },
  setup(props) {
    const positions = reactive<{ listPositions: PositionResponse[] }>({
      listPositions: []
    })

    onMounted(async () => {
      const response = await Network.position.getByScheduleId(props.scheduleId)
      positions.listPositions = response.data
    })

    const items = computed(() => {
      return positions.listPositions.map((p) => {
        return { title: p.name, value: p.id }
      })
    })

    return {
      items
    }
  }
}
</script>
