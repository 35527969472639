<template>
  <div class="w-full mt-4 overflow-y-auto overflow-x-hidden" style="grid-area: table">
    <TableUsers
      v-for="companyUser in users?.data ?? []"
      :key="companyUser.user.id"
      :data="companyUser"
    />
  </div>
</template>
<script lang="ts" setup>
import TableUsers from '@/components/designSystem/Table/TableUsers.vue'
import { useUserStore } from '@/pinia-store/useUsersStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

const userStore = useUserStore()
const { users } = storeToRefs(userStore)
onMounted(async () => {
  await userStore.getAllUsers()
})
</script>
