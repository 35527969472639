<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 my-8 gap-4 max-w-full md:max-w-3xl">
      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Senha atual inválida"
        width="100%"
        label="Senha atual"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="currentPassword"
        required
      />

      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Nova senha inválida"
        width="100%"
        label="Nova senha"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="newPassword"
        required
      />

      <Input
        :isError="isErrorUserName"
        @input="onChangeUserName"
        errorMessage="Nova senha inválida"
        width="100%"
        label="Confirmar senha"
        pattern="^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        :value="reWriteNewPassword"
        required
      />
    </div>
  </div>

  <div class="flex justify-end mt-10">
    <FilledButton
      text="Salvar alterações"
      width="180px"
      @click="() => toast.success('Senha alterada com sucesso!')"
    />
  </div>
</template>

<script>
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import Input from '@/components/designSystem/Inputs/Input.vue'
import { useToast } from 'vue-toastification'

export default {
  name: 'PasswordPanel',
  components: {
    Input,
    FilledButton
  },
  setup() {
    const toast = useToast()

    return { toast }
  }
}
</script>
