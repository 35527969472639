<template>
  <Popper>
    <span class="filter-btn"
      ><clock-icon />
      <p>Horário</p>
    </span>
    <template #content>
      <div class="max-h-[285px]">
        <div
          class="flex gap-1 justify-center items-center w-[149px] text-[16px] font-normal p-[16px]"
        >
          <input
            class="py-[4px] px-[5px] w-[55px] focus:bg-[#F0F0F4] focus-visible:outline-0 focus:rounded-t focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:border-b-2 focus:border-[#2f7f91]"
            v-model="hourStart"
          />
          -
          <input
            class="py-[4px] px-[5px] w-[55px] focus:bg-[#F0F0F4] focus-visible:outline-0 focus:rounded-t focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:border-b-2 focus:border-[#2f7f91]"
            v-model="hourEnd"
          />
        </div>
        <div
          class="relative flex w-[149px] text-[14px] font-normal pb-[16px] max-h-[190px] scrolbar-filter overflow-y-scroll"
        >
          <div class="h-full flex flex-col items-center max-h-[190px] mr-[19px] ml-[13px]">
            <span
              class="cursor-pointer p-[6px] hover:bg-gray-200"
              @click="setHour('start', hour)"
              v-for="hour of optionsHours"
              :key="hour"
              >{{ hour }}</span
            >
          </div>
          <div class="h-full flex flex-col items-center max-h-[190px]">
            <span
              class="cursor-pointer p-[6px] hover:bg-gray-200"
              @click="setHour('end', hour)"
              v-for="hour of optionsHours"
              :key="hour"
              >{{ hour }}</span
            >
          </div>
        </div>
        <div
          @click="addFilter(`${hourStart}-${hourEnd}`)"
          class="mt-1 cursor-pointer border border:bg-gray-100 w-full text-xs font-semibold py-1 h-[25px] rounded-b-md"
        >
          Aplicar
        </div>
      </div>
    </template>
  </Popper>
</template>

<script lang="ts">
import { computed, ref } from 'vue'
import Popper from 'vue3-popper'
import ClockIcon from '@/assets/icons/clock.vue'
import { useStationStore } from '@/pinia-store/useStationStore'

export default {
  components: {
    ClockIcon,
    Popper
  },
  setup() {
    const stationStore = useStationStore()
    const hourStart = ref('00:00')
    const hourEnd = ref('00:00')
    const selectedHours = ref({ startHour: '', endHours: '' })
    const optionsHours = [
      '00:00',
      '00:15',
      '00:30',
      '00:45',
      '01:00',
      '01:15',
      '01:30',
      '01:45',
      '02:00',
      '02:15',
      '02:30',
      '02:45',
      '03:00',
      '03:15',
      '03:30',
      '03:45',
      '04:00',
      '04:15',
      '04:30',
      '04:45',
      '05:00',
      '05:15',
      '05:30',
      '05:45',
      '06:00',
      '06:15',
      '06:30',
      '06:45',
      '07:00',
      '07:15',
      '07:30',
      '07:45',
      '08:00',
      '08:15',
      '08:30',
      '08:45',
      '09:00',
      '09:15',
      '09:30',
      '09:45',
      '10:00',
      '10:15',
      '10:30',
      '10:45',
      '11:00',
      '11:15',
      '11:30',
      '11:45',
      '12:00',
      '12:15',
      '12:30',
      '12:45',
      '13:00',
      '13:15',
      '13:30',
      '13:45',
      '14:00',
      '14:15',
      '14:30',
      '14:45',
      '15:00',
      '15:15',
      '15:30',
      '15:45',
      '16:00',
      '16:15',
      '16:30',
      '16:45',
      '17:00',
      '17:15',
      '17:30',
      '17:45',
      '18:00',
      '18:15',
      '18:30',
      '18:45',
      '19:00',
      '19:15',
      '19:30',
      '19:45',
      '20:00',
      '20:15',
      '20:30',
      '20:45',
      '21:00',
      '21:15',
      '21:30',
      '21:45',
      '22:00',
      '22:15',
      '22:30',
      '22:45',
      '23:00',
      '23:15',
      '23:30',
      '23:45'
    ]

    // eslint-disable-next-line
    const optionsHoursStart = computed(() =>
      optionsHours.filter(
        (item) => Date.parse(`01/01/2011 ${item}`) > Date.parse(`01/01/2011 ${hourStart.value}`)
      )
    )
    function setHour(type: string, hour: string) {
      if (type === 'start') hourStart.value = hour
      else hourEnd.value = hour
    }
    const addFilter = (val: string) => {
      const hoursSplited = val.split('-')
      const startHour = hoursSplited[0]
      const endHour = hoursSplited[1]
      stationStore.setHoursFilter(startHour, endHour)
    }

    return {
      hourStart,
      hourEnd,
      selectedHours,
      optionsHours,
      optionsHoursStart,
      setHour,
      addFilter
    }
  }
}
</script>

<style></style>
