<template>
  <!-- <div class="flex justify-start gap-1 flex-wrap"> -->
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="3"
      xl="3"
      v-for="(companyDoctor, index) in companyDoctors"
      :key="index"
    >
      <Card :companyDoctor="companyDoctor" />
    </v-col>
  </v-row>
  <!-- </div> -->
</template>

<script lang="ts">
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import { Card } from '../atoms'

export default {
  components: {
    Card
  },
  props: {
    companyDoctors: {
      type: Array<DoctorCompanyResponse>,
      default: () => {
        return []
      }
    }
  },
  setup() {}
}
</script>
