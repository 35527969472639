import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateSchedule = _resolveComponent("CreateSchedule")!
  const _component_CreatePosition = _resolveComponent("CreatePosition")!
  const _component_ListSchedules = _resolveComponent("ListSchedules")!
  const _component_CreateHospitalModal = _resolveComponent("CreateHospitalModal")!
  const _component_RemoveScheduleModal = _resolveComponent("RemoveScheduleModal")!
  const _component_UpdateScheduleModal = _resolveComponent("UpdateScheduleModal")!
  const _component_PositionsModal = _resolveComponent("PositionsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateSchedule, { onOpenCreateHospitalModal: $setup.openCreateHospitalModal }, null, 8, ["onOpenCreateHospitalModal"]),
    _createVNode(_component_CreatePosition, { onOpenCreateHospitalModal: $setup.openCreateHospitalModal }, null, 8, ["onOpenCreateHospitalModal"]),
    _createVNode(_component_ListSchedules, {
      onRemoveSchedule: $setup.handleRemove,
      onUpdateSchedule: $setup.handleUpdate,
      onShowPositions: $setup.handleToShowPositions
    }, null, 8, ["onRemoveSchedule", "onUpdateSchedule", "onShowPositions"]),
    _createVNode(_component_CreateHospitalModal, {
      type: "create",
      modalStateProp: $setup.showCreateHospitalModal,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showCreateHospitalModal = false))
    }, null, 8, ["modalStateProp"]),
    _createVNode(_component_RemoveScheduleModal, { "schedule-id": $setup.scheduleIdToDelete }, null, 8, ["schedule-id"]),
    _createVNode(_component_UpdateScheduleModal, { schedule: $setup.scheduleToUpdate }, null, 8, ["schedule"]),
    _createVNode(_component_PositionsModal, { "schedule-id": $setup.scheduleIdToShowPositions }, null, 8, ["schedule-id"])
  ], 64))
}