<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<template>
  <v-btn
    v-bind="{ ...$props, ...$attrs }"
    class="button-generic flex items-center px-2 py-4 font-semibold duration-200 transform rounded-md text-sm"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :key="slot" :name="slot" v-bind="scope" />
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { VBtn } from 'vuetify/components'
// TODO Criar props para definir o type e aplicar estilos padroes conforme o type.
export default defineComponent({
  extends: VBtn,
  inheritAttrs: false,
  props: {
    text: { type: String, default: 'Button' },
    width: { type: String, default: '300px' },
    height: { type: String, default: 'auto' }
  },
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.button-generic {
  --v-hover-opacity: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
}
</style>
