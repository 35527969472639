<template>
  <div>
    <div ref="map" style="height: 300px; width: 100%" class="shadow-lg mb-12"></div>
    <div class="font-bold">
      {{ location }}
    </div>
    <div>
      {{ addressUser }}
    </div>
  </div>
</template>

<script>
import { string } from 'yup'

export default {
  props: {
    latitude: Number,
    longitude: Number,
    address: string
  },
  mounted() {
    this.initMap()
  },
  computed: {
    location() {
      return `Latitude: ${this.latitude} - Longitude: ${this.longitude}
        `
    },
    addressUser() {
      return `${this.address}`
    }
  },
  watch: {
    location() {
      const map = new google.maps.Map(this.$refs.map, {
        center: { lat: this.latitude, lng: this.longitude },
        zoom: 15
      })

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const marker = new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: map,
        draggable: true
      })
    }
  },
  methods: {
    initMap() {
      const map = new google.maps.Map(this.$refs.map, {
        center: { lat: this.latitude, lng: this.longitude },
        zoom: 15
      })

      const marker = new google.maps.Marker({
        position: { lat: this.latitude, lng: this.longitude },
        map: map,
        draggable: true
      })

      marker.addListener('dragend', (event) => {
        this.$emit('marker-dragged', { lat: event.latLng.lat(), lng: event.latLng.lng() })
      })
    },
    updateMap() {
      console.log(this.$refs)
    }
  }
}
</script>
