<template>
  <div class="w-full h-full grid-layout my-0">
    <div class="w-full flex flex-col lg:flex-row justify-between items-start gap-4">
      <div class="w-full lg:w-3/5">
        <SearchInput
          v-model="textInput"
          width="100%"
          label="Buscar unidade"
          :searchFunction="changeSearchInput"
        />
      </div>

      <div class="w-full lg:w-1/5 flex flex-col sm:flex-row gap-1">
        <FilledButton
          @click="healthUnitStore.isFilterApplied ? clearFilters() : openFilterModal()"
          :text="healthUnitStore.isFilterApplied ? 'Limpar Filtros' : 'Filtrar'"
          height="56px"
          :prepend-icon="!healthUnitStore.isFilterApplied ? mdiFilter : ''"
          class="w-full sm:w-auto bg-gray-500 hover:bg-gray-600"
        />
      </div>
      <div class="w-full lg:w-1/5 flex flex-col sm:flex-row gap-1">
        <FilledButton
          @click="handleOpenModal('create')"
          text="Cadastrar unidade"
          height="56px"
          class="w-full sm:w-auto"
        />
      </div>
    </div>

    <Spinner
      v-if="healthUnitStore.isLoading"
      :color="'primary'"
      :size="'5rem'"
      :width="'0.5rem'"
      class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50"
    />

    <div class="w-full mt-4">
      <div class="grid xl:flex grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:flex-wrap my-0 gap-4">
        <template v-for="(unit, index) in healthUnits?.data || []" :key="index">
          <CardHospital @mousedown="setHealthUnitToUpdate(unit)" :unit="unit" />
        </template>
      </div>
      <div class="mt-4">
        <v-pagination
          v-if="healthUnitStore.totalHealthUnits > itemsPerPage"
          v-model="currentPage"
          :total-visible="itemsPerPage"
          :length="pageCount"
          density="comfortable"
          color="primary"
          @update:model-value="changePage"
        />
      </div>
    </div>

    <!-- <div
      class="grid grid-cols-1 lg:hidden gap-4 my-0 mt-4"
      style="grid-area: buttons; height: 52px"
    >
      <FilledButton @click="handleOpenModal('create')" text="Cadastrar unidade" width="100%" />
    </div> -->
  </div>

  <HealthUnitModal
    v-if="toggleHealthUnitModal"
    :healthUnit="healthUnitToUpdate"
    :modalStateProp="toggleHealthUnitModal"
    @close="closeHealthUnitModal"
    :type="operationModal"
    :limit="itemsPerPage"
    :offset="healthUnitStore.calculateOffSet"
  />

  <FilterModal
    :modalStateProp="filterModalProps"
    @close="closeFilterModal"
    @apply-filters="applyFilters"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import Spinner from '@/components/others/Spinner.vue'
import { storeToRefs } from 'pinia'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import CardHospital from './components/CardHospital.vue'
import HealthUnitModal from './modals/health.unit.modal.vue'
import FilterModal from './modals/FilterModal.vue'
import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import { HealthUnitResponse } from '@/Network/Types/Responses/HealthUnitResponses'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { mdiFilter } from '@mdi/js'

const healthUnitStore = useHealthUnitStore()
const { healthUnits } = storeToRefs(healthUnitStore)

const textInput = ref('')

const currentPage = ref(1)
const itemsPerPage = ref(15)

const filterModalProps = ref(false)

const closeFilterModal = () => {
  filterModalProps.value = false
}

const openFilterModal = () => {
  textInput.value = ''
  filterModalProps.value = true
}

const closeHealthUnitModal = () => {
  toggleHealthUnitModal.value = false
}

const operationModal = ref<'create' | 'update'>('create')
const toggleHealthUnitModal = ref(false)
const healthUnitToUpdate = ref<HealthUnitResponse>()

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const emit = defineEmits(['close', 'apply-filters'])

const handleOpenModal = (operation: 'create' | 'update') => {
  operationModal.value = operation
  toggleHealthUnitModal.value = true
}

const setHealthUnitToUpdate = (unit: HealthUnitResponse) => {
  healthUnitToUpdate.value = unit
  handleOpenModal('update')
}

const changeSearchInput = async () => {
  healthUnitStore.applyFilters({ search: textInput.value })
  currentPage.value = 1
}

onMounted(async () => {
  await healthUnitStore.setFiltersFromQuery()
})

interface Company {
  title: string
  value: string
}

interface Filters {
  search: string
  names: string[]
  cnpjs: string[]
  ufs: string[]
  companies: Company[]
}

const pageCount = computed(() => {
  return Math.ceil(healthUnitStore.totalHealthUnits / healthUnitStore.healthUnitQuery.limit!)
})

const applyFilters = async (modalFields: Filters) => {
  const companyIds = modalFields.companies.map((company) => company.value)

  const filters = {
    names: modalFields.names,
    cnpjs: modalFields.cnpjs,
    ufs: modalFields.ufs,
    companies: companyIds
  }

  await healthUnitStore.applyFilters(filters)
  currentPage.value = 1
}

const clearFilters = async () => {
  textInput.value = ''
  healthUnitStore.clearFilters()
  currentPage.value = 1
}

const changePage = (page: number) => {
  currentPage.value = page
  healthUnitStore.changePage(page)
}
</script>
