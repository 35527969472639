import * as yup from 'yup'

export const LoginSchema = yup.object({
  email: yup.string().required('Email não foi informado!').email('Email inválido!'),
  password: yup.string().required('Senha não foi informada!')
})

export type LoginType = yup.InferType<typeof LoginSchema>

export type ErrorLoginType = { [K in keyof LoginType]: string }

const values: LoginType = {
  email: '',
  password: ''
}

const errors: ErrorLoginType = {
  email: '',
  password: ''
}

export const states = {
  values,
  errors
}
