import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { PermissionResponse, ResourceType } from '../Types/Responses/PermissionResponses'
import { UpsertPermissionsRequest } from '../Types/Requests/PermissionRequests'
export default class PermissionService extends Service {
  prefix: string

  constructor() {
    super()

    this.prefix = '/permissions'
  }

  async addPermissions(
    resourceId: number,
    resourceType: ResourceType,
    payload: UpsertPermissionsRequest
  ): Promise<PermissionResponse[]> {
    const { data } = await request.post<PermissionResponse[]>(
      `${this.getCompletePath()}/shared/${resourceType}/${resourceId}`,
      payload
    )
    return data
  }

  async upsertPermissions(
    resourceId: number,
    resourceType: ResourceType,
    payload: UpsertPermissionsRequest
  ): Promise<PermissionResponse[]> {
    const { data } = await request.put<PermissionResponse[]>(
      `${this.getCompletePath()}/shared/${resourceType}/${resourceId}`,
      payload
    )
    return data
  }

  async listPermissions(
    resourceType: ResourceType,
    resourceId: number
  ): Promise<PermissionResponse[]> {
    const { data } = await request.get<PermissionResponse[]>(
      `${this.getCompletePath()}/${resourceType}/${resourceId}`
    )
    return data
  }
}
