<template>
  <div class="station-form-category-container">
    <div
      v-if="urlConviteDoctor && Number(originalDoctor) === Number(doctor)"
      class="flex flex-col justify-center bg-warning p-3 rounded"
    >
      <span class="font-bold">O médico ainda está pendente!</span>
      <a href="" @click="copyConvite" class="text-sm flex justify-center items-center gap-1">
        <v-icon :icon="mdiContentCopy"></v-icon>
        Clique para copiar o link do convite
      </a>
    </div>
    <span class="station-form-category-title">Médico e Valores</span>
    <div class="station-form-category-content">
      <div class="station-form-category-columns">
        <v-autocomplete
          @update:model-value="setDoctor"
          :items="listDoctorsCompany"
          :model-value="doctor ? String(doctor) : undefined"
          class="w-full"
          color="primary20"
          clearable
          label="Médico"
          theme="light"
          variant="outlined"
          density="comfortable"
          :disabled="!company"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="item?.raw?.title"
              :subtitle="item?.raw?.subtitle"
            ></v-list-item>
          </template>
        </v-autocomplete>
        <v-text-field
          v-if="!updateForm || modifiedValue || !originalValue"
          @update:model-value="setValue"
          :model-value="value"
          class="w-full max-w-[290px]"
          prefix="R$"
          type="number"
          :label="
            (modifiedValue && modifiedValueEnabled) || !modifiedValueEnabled
              ? 'Valor acordado com o médico'
              : 'Valor original do plantão'
          "
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
        <v-text-field
          v-else
          :model-value="originalValue"
          disabled
          class="w-full max-w-[290px]"
          prefix="R$"
          type="number"
          label="Valor original do plantão"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
      </div>
      <div class="station-form-category-columns">
        <v-checkbox
          @click="setInCash"
          :model-value="inCash"
          density="compact"
          label="Pagamento á vista"
          color="primary"
        />
        <v-checkbox
          @click="setAdvanceRequested"
          :model-value="advanceRequested"
          density="compact"
          color="primary"
          label="Antecipação solicitada"
        />
        <v-checkbox
          @click="setAdvancePaid"
          :model-value="advancePaid"
          density="compact"
          color="primary"
          label="Antecipação paga"
        />
        <v-checkbox
          v-if="updateForm || modifiedValueEnabled"
          @click="setModifiedValue"
          :model-value="modifiedValue"
          density="compact"
          color="primary"
          label="Valor Modificado"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'
import { storeToRefs } from 'pinia'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import { mdiContentCopy } from '@mdi/js'
import { useToast } from 'vue-toastification'
const toast = useToast()

const copyConvite = async (e) => {
  e.preventDefault()
  await navigator.clipboard.writeText(props.urlConviteDoctor || '')
  toast.success('Link copiado com sucesso')
}

const props = defineProps([
  'doctor',
  'value',
  'company',
  'inCash',
  'modifiedValue',
  'advanceRequested',
  'advancePaid',
  'originalDoctor',
  'urlConviteDoctor',
  'originalValue',
  'updateForm',
  'modifiedValueEnabled'
])
const emit = defineEmits([
  'update:doctor',
  'update:value',
  'update:inCash',
  'update:advanceRequested',
  'update:advancePaid',
  'update:modifiedValue'
])

const { listDoctorsCompany } = storeToRefs(useDoctorStore())

const setDoctor = (doctorId: string) => emit('update:doctor', Number(doctorId))
const setValue = (value: string) => emit('update:value', Number(value))
const setAdvanceRequested = () => emit('update:advanceRequested', !props.advanceRequested)
const setAdvancePaid = () => emit('update:advancePaid', !props.advancePaid)
const setInCash = () => emit('update:inCash', !props.inCash)
const setModifiedValue = () => emit('update:modifiedValue', !props.modifiedValue)
</script>
