import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { CreateGroupRequest } from '../Types/Requests/GroupRequests'
import { GroupResponse, ListGroupsResponse } from '../Types/Responses/GroupResponses'
export default class GroupService extends Service {
  prefix: string

  constructor() {
    super()

    this.prefix = '/groups'
  }

  async create(payload: CreateGroupRequest): Promise<GroupResponse> {
    const { data } = await request.post<GroupResponse>(`${this.getCompletePath()}`, payload)
    return data
  }

  async list(): Promise<ListGroupsResponse> {
    const { data } = await request.get<ListGroupsResponse>(this.getCompletePath())
    return data
  }
  async remove(groupId: number): Promise<void> {
    await request.delete(`${this.getCompletePath()}/${groupId}`)
  }
  async update(groupId: number, payload: CreateGroupRequest): Promise<void> {
    await request.put(`${this.getCompletePath()}/${groupId}`, payload)
  }
}
