import api from './api'

export default class PutRequest {
  url: string

  body: any
  headers: any

  constructor(url: string, body: any, headers?: Record<string, string>) {
    this.url = url
    this.headers = headers
    this.body = body
  }

  async run<R>() {
    return api.put<R>(this.url, this.body, { headers: this.headers })
  }
}
