<template>
  <modal-vue
    @close="handleToggleModal"
    :modalActive="modals[modalName]"
    :hasFooterBorder="true"
    isFilterModal
  >
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Aplicar Filtros</strong>
      </div>
    </template>
    <div
      class="text-left input w-full flex flex-col gap-2 input mt-2 max-h-[420px] px-[8px] overflow-y-scroll"
    >
      <MultipleSchedules
        v-model:company="filterStates.company"
        v-model:healthUnits="filterStates.healthUnits"
        v-model:schedules="filterStates.schedules"
        v-model:doctors="filterStates.doctors"
        v-model:positions="filterStates.positions"
      />

      <div class="flex justify-between gap-2">
        <v-text-field
          v-model="filterStates.valueMin"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor Mínimo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor da vaga"
        />
        <v-text-field
          v-model="filterStates.valueMax"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor Máximo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor da vaga"
        />
      </div>

      <div class="flex md:flex-nowrap flex-wrap justify-between gap-2">
        <v-text-field
          v-model="filterStates.paymentValueMin"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor aprovado mínimo"
          color="primary20"
          variant="outlined"
          density="compact"
          hint="Valor aprovado pelo hospital"
        />
        <v-text-field
          class="w-full"
          v-model="filterStates.paymentValueMax"
          prefix="R$"
          type="number"
          label="Valor aprovado máximo"
          color="primary20"
          variant="outlined"
          hint="Valor aprovado pelo hospital"
          density="compact"
        />
      </div>

      <div class="flex flex-col gap-2">
        <div class="flex gap-2">
          <DatePicker v-model="filterStates.startDate" :masks="{ input: `DD/MM/YYYY` }">
            <template #default="{ inputValue, inputEvents }">
              <v-text-field
                placeholder="Dia Inicial"
                :prepend-inner-icon="mdiCalendar"
                :value="inputValue"
                v-on="inputEvents"
                density="compact"
                variant="outlined"
                class="w-full"
                color="primary20"
              />
            </template>
          </DatePicker>
          <DatePicker v-model="filterStates.endDate" :masks="{ input: `DD/MM/YYYY` }">
            <template #default="{ inputValue, inputEvents }">
              <v-text-field
                placeholder="Dia Final"
                :prepend-inner-icon="mdiCalendar"
                :value="inputValue"
                v-on="inputEvents"
                density="compact"
                variant="outlined"
                class="w-full"
                color="primary20"
              />
            </template>
          </DatePicker>
        </div>
        <v-autocomplete
          clearable
          v-model="filterStates.days"
          multiple
          class="w-full"
          color="primary20"
          label="Dias da semana"
          :items="[
            { title: 'Domingo', value: 0 },
            { title: 'Segunda', value: 1 },
            { title: 'Terça', value: 2 },
            { title: 'Quarta', value: 3 },
            { title: 'Quinta', value: 4 },
            { title: 'Sexta', value: 5 },
            { title: 'Sábado', value: 6 }
          ]"
          variant="outlined"
          density="compact"
        />
        <div class="flex flex-wrap md:flex-nowrap justify-between max-w-2xl gap-2">
          <v-autocomplete
            clearable
            v-model="filterStates.advanceRequested"
            class="w-full"
            color="primary20"
            label="Antecipação Solicitada"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />
          <v-autocomplete
            clearable
            v-model="filterStates.advancePaid"
            class="w-full"
            color="primary20"
            label="Antecipação Paga"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />
        </div>
        <div class="flex justify-between max-w-2xl gap-2">
          <v-autocomplete
            clearable
            v-model="filterStates.inCash"
            class="w-full"
            color="primary20"
            label="Pagamento á vista"
            :items="[
              { title: 'Sim', value: true },
              { title: 'Não', value: false }
            ]"
            variant="outlined"
            density="compact"
          />

          <v-autocomplete
            clearable
            v-model="filterStates.ourTeam"
            class="w-full"
            color="primary20"
            label="Nosso plantão?"
            :items="stationOur"
            variant="outlined"
            density="compact"
          />
        </div>
      </div>
      <div class="flex gap-2">
        <v-autocomplete
          clearable
          v-model="filterStates.stationType"
          class="w-full"
          color="primary20"
          label="Periodicidade"
          :items="stationTypes"
          variant="outlined"
          density="compact"
        />
        <v-autocomplete
          clearable
          v-model="filterStates.status"
          class="w-full"
          color="primary20"
          label="Status do plantão"
          :items="[
            { title: 'Pendente', value: 'waiting' },
            { title: 'Confirmado', value: 'confirmed' }
          ]"
          variant="outlined"
          density="compact"
        />
      </div>
      <div class="flex gap-2">
        <v-autocomplete
          clearable
          v-model="filterStates.withCheckin"
          class="w-full"
          color="primary20"
          label="Checkin"
          :items="[
            { title: 'Com checkin', value: true },
            { title: 'Sem checkin', value: false }
          ]"
          variant="outlined"
          density="compact"
        />
        <v-autocomplete
          clearable
          v-model="filterStates.withCheckout"
          class="w-full"
          color="primary20"
          label="Checkout"
          :items="[
            { title: 'Com checkout', value: true },
            { title: 'Sem checkout', value: false }
          ]"
          variant="outlined"
          density="compact"
        />
      </div>
    </div>

    <template #footer>
      <v-row class="pt-4">
        <v-col cols="6">
          <ghost-button @click="handleClearFilters" width="100%" text="Limpar filtros" />
        </v-col>
        <v-col cols="6">
          <filled-button
            :loading="isLoading"
            @click="handleSubmit"
            text="Aplicar Filtros"
            height="52px"
            width="100%"
          />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { stationTypes, stationOur } from '@/hooks/useCommon'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { filterStates, useFilterStationsStore } from './useFilterStation'
import MultipleSchedules from '@/components/Autocompletes/MultipleSchedules.vue'
import { mdiCalendar } from '@mdi/js'
import { DatePicker } from 'v-calendar'
import { ref } from 'vue'

/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.FILTER_STATION
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */
const isLoading = ref(false)

const handleSubmit = async () => {
  isLoading.value = true
  await useFilterStations.setFilters().finally(() => {
    isLoading.value = false
    handleToggleModal()
  })
}
const useFilterStations = useFilterStationsStore()
const handleClearFilters = async () => {
  handleToggleModal()
  await useFilterStations.clearFilters()
}
</script>
