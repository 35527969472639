<template>
  <v-row dense class="items-center" justify="space-between">
    <v-col cols="12" md="10">
      <SearchInput
        v-model="textInput"
        :search-function="onSearchByTabIndex"
        width="80%"
        :loading="loading"
        :label="`Buscar ${tabsName[tab]}`"
        hide-details
      />
    </v-col>
    <v-col cols="12" md="2">
      <FilledButton
        @click="toggleCreateModal"
        :text="`Criar ${tabsName[tab]}`"
        width="100%"
        height="56px"
      />
    </v-col>
    <v-col cols="12">
      <div class="w-full h-full grid-layout my-0">
        <!-- Tabs -->
        <ul class="gap-0 border-b flex sm:justify-start justify-between select-none">
          <li>
            <GenericButton
              width="150px"
              @click="() => setActiveTab(1)"
              :variant="tab === 1 ? 'tonal' : 'text'"
            >
              Usuários
            </GenericButton>
          </li>

          <li>
            <GenericButton
              width="150px"
              @click="() => setActiveTab(2)"
              :variant="tab === 2 ? 'tonal' : 'text'"
            >
              Grupos
            </GenericButton>
          </li>
        </ul>

        <!-- Content -->
        <div class="flex flex-col gap-10 justify-between">
          <transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            enter-active-class="transition duration-300"
            leave-active-class="transition duration-300"
          >
            <div v-if="tab === 1">
              <UserTab />
            </div>
            <div v-else-if="tab === 2">
              <GroupTab />
            </div>
          </transition>
          <!-- <Paginate /> -->
        </div>

        <CreateUserModal @close="toggleModalCreateUser" :modalActive="showModalCreateUser" />
        <DeleteUserModal @close="toggleModalUsersGroup" :modalActive="showModalUsersGroup" />
      </div>
    </v-col>
  </v-row>
  <teleport to="#app">
    <CreateGroupModal />
  </teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue'
// Group
import GroupTab from './group/group.tab.vue'
import CreateGroupModal from './group/modal/create.group.modal.vue'

import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GenericButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import CreateUserModal from './components/CreateUserModal/Index.vue'
import UserTab from './user.tab.vue'
import DeleteUserModal from './components/DeleteUserModal/Index.vue'

import SearchInput from '@/components/designSystem/Inputs/SearchInput.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { useGroupStore } from '@/pinia-store/useGroupStore'

const modalStore = useModalStore()

const showModalCreateUser = ref(false)
const showModalUsersGroup = ref(false)
const textInput = ref('')
const loading = ref(false)
const tab = ref(1)
const tabsName = ref({ '1': 'usuários', '2': 'grupos' })

const groupStore = useGroupStore()
const onSearchByTabIndex = async () => {
  if (tab.value == 1) {
    console.log('searching users')
  }
  if (tab.value == 2) {
    groupStore.applyFilters({ searchTerm: textInput.value })
  }
}

const setActiveTab = async (newTab: number) => {
  tab.value = newTab
  await onSearchByTabIndex()
}

const toggleCreateModal = () => {
  if (tab.value === 1) {
    showModalCreateUser.value = !showModalCreateUser.value
    return
  }
  modalStore.toggleModal(EModals.CREATE_GROUP_MODAL)
}

const toggleModalCreateUser = () => {
  showModalCreateUser.value = !showModalCreateUser.value
}

const toggleModalUsersGroup = () => {
  showModalUsersGroup.value = !showModalUsersGroup.value
}
</script>

<style scoped>
.grid-layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 64px 1fr 100%;
  gap: 0px 0px;
  grid-template-areas:
    'search'
    'table';
}

@media only screen and (max-width: 1024px) {
  .grid-layout {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 58px 1fr 100%;
    gap: 0px 0px;
    grid-template-areas:
      'search'
      'table'
      'buttons';
  }
}
</style>
