// src/stores/useDoctorStore.ts
import { defineStore } from 'pinia'
import { router } from '@/router'
import { useRoute, LocationQuery } from 'vue-router'
import Network from '@/Network'
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
import {
  CompanyDoctorInviteStatusEnum,
  CompanyDoctorInviteSearchParams
} from '@/Network/Types/Requests/CompanyRequests'

interface DoctorFilters {
  search: string
  names: string[]
  crms: string[]
  crmUFs: string[]
  companies: string[]
  inviteStatus?: CompanyDoctorInviteStatusEnum | CompanyDoctorInviteStatusEnum[]
}

interface DoctorState {
  doctorsCompany: DoctorCompanyResponse[] | undefined
  doctorQuery: CompanyDoctorInviteSearchParams
  totalDoctors: number
  isLoading: boolean
  isError: boolean
  errorMessage: string | null
  listDoctorsCompany: { title: string; subtitle: string; value: string }[]
  page: number
}

export const useDoctorStore = defineStore('doctor', {
  state: (): DoctorState => ({
    doctorsCompany: undefined,
    doctorQuery: {
      search: '',
      names: '',
      crms: '',
      crmUFs: '',
      companies: '',
      inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED,
      limit: 15,
      offset: 0
    },
    totalDoctors: 0,
    isLoading: false,
    isError: false,
    errorMessage: null,
    listDoctorsCompany: [],
    page: 1
  }),
  getters: {
    isFilterApplied(state): boolean {
      return (
        (state.doctorQuery.search?.trim().length ?? 0) > 0 ||
        (state.doctorQuery.names?.trim().length ?? 0) > 0 ||
        (state.doctorQuery.crms?.trim().length ?? 0) > 0 ||
        (state.doctorQuery.crmUFs?.trim().length ?? 0) > 0 ||
        (state.doctorQuery.companies?.trim().length ?? 0) > 0 ||
        (state.doctorQuery.inviteStatus?.toString.length ?? 0) > 0
      )
    }
  },
  actions: {
    async fetchDoctors(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.doctorsCompany) {
        try {
          this.isError = false
          this.errorMessage = null
          this.isLoading = true
          const { data, count } = await Network.company.getCompanyDoctors(this.doctorQuery)
          this.doctorsCompany = data
          this.listDoctorsCompany =
            this.doctorsCompany?.map((d) => {
              const crm = d.doctor.mainCrm
              return {
                title: d.doctor.mainCrm.name,
                subtitle: `${crm.number}-${crm.uf}`,
                value: String(d.doctor.id)
              }
            }) || []
          this.totalDoctors = count
        } catch (error) {
          console.error('Erro ao obter médicos da empresa:', error)
          this.doctorsCompany = []
          this.totalDoctors = 0
          this.isError = true

          if (error instanceof Error) {
            this.errorMessage = error.message
          } else {
            this.errorMessage = 'Erro desconhecido'
          }
        } finally {
          this.isLoading = false
        }
      }
    },
    serializeFilters(): Record<string, string> {
      const params = new URLSearchParams()

      if (this.doctorQuery.search) {
        params.set('search', this.doctorQuery.search)
      }
      if (this.doctorQuery.names && this.doctorQuery.names.length > 0) {
        params.set('names', this.doctorQuery.names)
      }
      if (this.doctorQuery.crms && this.doctorQuery.crms.length > 0) {
        params.set('crms', this.doctorQuery.crms)
      }
      if (this.doctorQuery.crmUFs && this.doctorQuery.crmUFs.length > 0) {
        params.set('crmUFs', this.doctorQuery.crmUFs)
      }
      if (this.doctorQuery.companies && this.doctorQuery.companies.length > 0) {
        params.set('companies', this.doctorQuery.companies)
      }

      if (this.doctorQuery.inviteStatus) {
        if (Array.isArray(this.doctorQuery.inviteStatus)) {
          params.set('inviteStatus', this.doctorQuery.inviteStatus.join(','))
        } else {
          params.set('inviteStatus', this.doctorQuery.inviteStatus)
        }
      }

      if (this.doctorQuery.limit) {
        params.set('limit', this.doctorQuery.limit.toString())
      }
      if (this.doctorQuery.offset !== undefined) {
        params.set('offset', this.doctorQuery.offset.toString())
      }

      const queryObject: Record<string, string> = {}
      params.forEach((value, key) => {
        queryObject[key] = value
      })

      return queryObject
    },
    updateQueryString() {
      const queryString = this.serializeFilters()
      router.replace({ query: queryString })
    },
    deserializeFilters(query: LocationQuery): DoctorFilters {
      return {
        search: Array.isArray(query.search) ? String(query.search[0]) : query.search ?? '',
        names: Array.isArray(query.names)
          ? query.names.join(',').split(',')
          : query.names
          ? query.names.split(',')
          : [],
        crms: Array.isArray(query.crms)
          ? query.crms.join(',').split(',')
          : query.crms
          ? query.crms.split(',')
          : [],
        crmUFs: Array.isArray(query.crmUFs)
          ? query.crmUFs.join(',').split(',')
          : query.crmUFs
          ? query.crmUFs.split(',')
          : [],
        companies: Array.isArray(query.companies)
          ? query.companies.join(',').split(',')
          : query.companies
          ? query.companies.split(',')
          : []
      }
    },
    async setFiltersFromQuery() {
      const route = useRoute()
      const query = route.query

      const filters: DoctorFilters = this.deserializeFilters(query)

      this.doctorQuery.search = filters.search
      this.doctorQuery.names = filters.names.length > 0 ? filters.names.join(',') : ''
      this.doctorQuery.crms = filters.crms.length > 0 ? filters.crms.join(',') : ''
      this.doctorQuery.crmUFs = filters.crmUFs.length > 0 ? filters.crmUFs.join(',') : ''
      this.doctorQuery.companies = filters.companies.length > 0 ? filters.companies.join(',') : ''

      this.doctorQuery.inviteStatus =
        typeof query.inviteStatus === 'string'
          ? (query.inviteStatus as CompanyDoctorInviteStatusEnum)
          : CompanyDoctorInviteStatusEnum.ACCEPTED

      this.doctorQuery.limit = query.limit ? Number(query.limit) : 15
      this.doctorQuery.offset = query.offset ? Number(query.offset) : 0

      await this.fetchDoctors()
    },
    async applyFilters(newFilters: Partial<DoctorFilters>, isUpdateUrlQueryString: boolean = true) {
      if (newFilters.search !== undefined) {
        this.doctorQuery.search = newFilters.search
      }
      if (newFilters.names !== undefined) {
        this.doctorQuery.names = newFilters.names.join(',')
      }
      if (newFilters.crms !== undefined) {
        this.doctorQuery.crms = newFilters.crms.join(',')
      }
      if (newFilters.crmUFs !== undefined) {
        this.doctorQuery.crmUFs = newFilters.crmUFs.join(',')
      }
      if (newFilters.companies !== undefined) {
        this.doctorQuery.companies = newFilters.companies.join(',')
      }

      if (newFilters.inviteStatus !== undefined) {
        this.doctorQuery.inviteStatus = newFilters.inviteStatus
      }

      this.doctorQuery.offset = 0

      if (isUpdateUrlQueryString) {
        this.updateQueryString()
      }

      await this.fetchDoctors(true)
    },

    clearFilters() {
      this.doctorQuery = {
        search: '',
        names: '',
        crms: '',
        crmUFs: '',
        companies: '',
        inviteStatus: CompanyDoctorInviteStatusEnum.ACCEPTED,
        limit: 15,
        offset: 0
      }
      this.updateQueryString()
      this.fetchDoctors(true)
    },
    changePage(page: number) {
      this.page = page
      this.doctorQuery.offset = (page - 1) * this.doctorQuery.limit!
      this.updateQueryString()
      this.fetchDoctors(true)
    },
    calculateOffSet(): number {
      return (this.page - 1) * this.doctorQuery.limit!
    }
  }
})
