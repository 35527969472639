import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.companyDoctors, (companyDoctor, index) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          cols: "12",
          sm: "6",
          md: "6",
          lg: "3",
          xl: "3",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, { companyDoctor: companyDoctor }, null, 8, ["companyDoctor"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}