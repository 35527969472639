import request from '@/Network/Requests/request'
import Service from '@/Network/Services/Service'
import { ListSchedulesResponse, ScheduleResponse } from '../Types/Responses/ScheduleUnitResponses'
import { CreateSchedule, FiltersSchedule } from '../Types/Requests/ScheduleRequests '

export default class ScheduleService extends Service {
  prefix: string

  constructor() {
    super()
    this.prefix = '/schedules'
  }

  async create(healthUnitId: number, payload: CreateSchedule): Promise<ScheduleResponse> {
    try {
      const { data } = await request.post<ScheduleResponse>(
        `${this.getCompletePath()}/healthUnit/${healthUnitId}`,
        payload
      )
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async list(filters?: FiltersSchedule): Promise<ListSchedulesResponse> {
    try {
      const { data } = await request.get<ListSchedulesResponse>(this.getCompletePath(), filters)
      return data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async remove(scheduleId: number): Promise<void> {
    try {
      await request.delete(`${this.getCompletePath()}/${scheduleId}`)
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async getById(scheduleId: number): Promise<ScheduleResponse> {
    try {
      const response = await request.get<ScheduleResponse>(
        `${this.getCompletePath()}/${scheduleId}`
      )
      return response.data
    } catch (e) {
      throw new Error('Ocorreu um erro')
    }
  }

  async update(
    scheduleId: number,
    payload: CreateSchedule
  ): Promise<ScheduleResponse> {
    const { data } = await request.put<ScheduleResponse>(
      `${this.getCompletePath()}/${scheduleId}`,
      payload
    )
    return data
  }
}
