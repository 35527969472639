import { defineStore } from 'pinia'
import Network from '@/Network'
import { CreateSchedule } from '@/Network/Types/Requests/ScheduleRequests '
import { CompanyResponse, Company } from '@/Network/Types/Responses/CompanyResponses'

export type CompanyState = {
  myCompanies: Company[]
  listCompanies: { title: string; value: string }[]
  companyIdsSelected: Set<number>
}

export const useCompanyStore = defineStore('company', {
  state: (): CompanyState => ({
    myCompanies: [],
    listCompanies: [],
    companyIdsSelected: new Set<number>()
  }),
  actions: {
    async getMyCompanies(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.myCompanies.length) {
        try {
          const response: CompanyResponse = await Network.company.list()
          this.myCompanies = response.data ?? response
          this.listCompanies = this.myCompanies.map((c) => ({ title: c.name, value: String(c.id) }))
          const listCompaniesIds: number[] = this.myCompanies.map((c) => c.id)
          Network.company.persistLocalCompanyList(listCompaniesIds)
        } catch (error) {
          console.error('Erro ao obter empresas:', error)
          this.myCompanies = []
          this.listCompanies = []
          this.companyIdsSelected.clear()
        }
      } else if (this.myCompanies.length) {
        const listCompaniesIds: number[] = this.myCompanies.map((c) => c.id)
        Network.company.persistLocalCompanyList(listCompaniesIds)
      }
    },
    setCompanyIdsSelected({ id, checked }: { id: number; checked: boolean }) {
      if (checked) this.companyIdsSelected.add(id)
      else this.companyIdsSelected.delete(id)
      Network.company.persistLocalCompanyList(Array.from(this.companyIdsSelected))
    },
    async create({ healthUnitId, payload }: { healthUnitId: number; payload: CreateSchedule }) {
      await Network.schedule.create(healthUnitId, payload)
    },
    async remove(scheduleId: number): Promise<void> {
      await Network.schedule.remove(scheduleId)
    }
  }
})
