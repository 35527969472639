import { TYPE, POSITION } from 'vue-toastification'
import SuccessIcon from '@/assets/icons/success.vue'
import CloseIcon from '@/assets/icons/closeCircle.vue'

export const toastOptions = {
  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: 3000,
      hideProgressBar: true,
      icon: CloseIcon,
      toastClassName: 'toast-error',
      closeButtonClassName: 'toast-close-button',
      position: POSITION.BOTTOM_LEFT
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      hideProgressBar: true,
      icon: SuccessIcon,
      toastClassName: 'toast-success',
      closeButtonClassName: 'toast-close-button',
      position: POSITION.BOTTOM_LEFT
    },
    [TYPE.INFO]: {
      timeout: 3000,
      hideProgressBar: false,
      toastClassName: 'toast-info',
      position: POSITION.TOP_LEFT
    }
  }
}
