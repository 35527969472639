export const timezones: {
  title: string
  subtitle: string
  value: string
}[] = [
  {
    title: 'Noronha',
    subtitle: 'Horário Padrão de Fernando de Noronha',
    value: '-02:00'
  },
  {
    title: 'Brasília',
    subtitle: 'Horário Padrão de Brasília',
    value: '-03:00'
  },
  {
    title: 'Amazonas',
    subtitle: 'Horário Padrão do Amazonas',
    value: '-04:00'
  },
  {
    title: 'Acre',
    subtitle: 'Horário Padrão do Acre',
    value: '-05:00'
  }
]

export const stationTypes = [
  { value: 'COBERTURA', title: 'Cobertura' },
  { value: 'SEMANAL', title: 'Fixo' },
  { value: 'EXTRA', title: 'Extra' }
]

export const stationOur = [
  { value: true, title: 'Sim' },
  { value: false, title: 'Não' }
]

export const openedStationOpts = [
  { value: false, title: 'Preenchida' },
  { value: true, title: 'Não preenchida' }
]

export const DEFAULT_MESSAGES = {
  error: 'Ocorreu um erro inesperado, tente novamente mais tarde.',
  success: 'Operação realizada com sucesso.'
}
