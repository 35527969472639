<template>
  <header class="flex items-center justify-between px-8 py-4 bg-white">
    <div class="flex items-center">
      <button
        v-if="isMobile"
        @click="showSideBar"
        class="text-gray-500 focus:outline-none lg:block"
      >
        <left-arrow v-if="isOpen" />
        <rigth-arrow v-else />
      </button>
    </div>

    <div class="flex items-center">
      <NotificationModal />

      <div class="relative">
        <button @click="dropdownOpen = !dropdownOpen" class="flex relative items-center">
          <v-avatar color="primary" class="mr-2">
            <span class="text-md font-bold">{{ getInitalsName(authStore.getUserName) }}</span>
          </v-avatar>

          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33333 1.66667L5.29289 5.62623C5.68341 6.01676 6.31658 6.01676 6.7071 5.62623L10.6667 1.66667"
              stroke="#2F7F91"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 z-10 w-full h-full"
        ></div>
        <transition
          enter-active-class="transition duration-150 ease-out transform"
          enter-from-class="scale-95 opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="transition duration-150 ease-in transform"
          leave-from-class="scale-100 opacity-100"
          leave-to-class="scale-95 opacity-0"
        >
          <div
            v-show="dropdownOpen"
            class="absolute right-0 z-20 w-48 py-1 mt-2 bg-white rounded-lg shadow-xl"
          >
            <a
              href="/perfil"
              class="px-4 py-2 flex items-center rounded-md text-xs text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-6 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              Editar Perfil</a
            >
            <a
              v-for="company of myCompanies"
              :key="company.id"
              class="px-4 py-2 flex justify-between items-center rounded-md text-sm text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              <v-avatar color="primary" size="small" class="mr-2">
                <span class="text-xs font-bold">{{ getInitalsName(company.name) }}</span>
              </v-avatar>
              <span class="w-full flex justify-between text-xs items-center text-left">
                {{ company.name }}
                <CheckBox
                  class="mr-0"
                  @update:checked="setCheck"
                  :checked="true"
                  :value="String(company.id)"
                  field-id="companies"
                />
              </span>
            </a>

            <div
              @click="logout"
              class="flex items-center px-4 py-2 rounded-md text-xs text-gray-700 hover:bg-indigo-600 hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-6 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              Deslogar
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { onMounted, ref } from 'vue'
import LeftArrow from '@/assets/icons/rightArrow.vue'
import RigthArrow from '@/assets/icons/leftArrow.vue'
import CheckBox from '@/components/designSystem/Checkbox/index.vue'
import { useSidebar } from '../hooks/useSidebar'
import NotificationModal from './others/NotificationModal.vue'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { storeToRefs } from 'pinia'
import Network from '@/Network'
import { useAuthStore } from '@/pinia-store/useAuthStore'

export default {
  components: {
    LeftArrow,
    RigthArrow,
    NotificationModal,
    CheckBox
  },
  setup() {
    const companyStore = useCompanyStore()
    const authStore = useAuthStore()
    const { myCompanies } = storeToRefs(useCompanyStore())

    const dropdownOpen = ref(false)

    const { isOpen, setIsOpen } = useSidebar()
    const notificationOpen = ref(false)
    const isMobile = ref(window.innerWidth < 800)

    function showSideBar() {
      setIsOpen(true)
    }

    onMounted(async () => {
      await companyStore.getMyCompanies()
      myCompanies.value.forEach(({ id }) => {
        companyStore.setCompanyIdsSelected({ checked: true, id })
      })

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 800
      })
    })

    const setCheck = ({ isChecked, value }: { isChecked: boolean; value: string }) => {
      companyStore.setCompanyIdsSelected({ checked: isChecked, id: +value })
    }

    const getInitalsName = (name: string) => {
      return name.substring(0, 2).toUpperCase()
    }

    return {
      showSideBar,
      dropdownOpen,
      isOpen,
      notificationOpen,
      isMobile,
      myCompanies,
      setCheck,
      logout: Network.auth.logout,
      getInitalsName,
      authStore
    }
  }
}
</script>
