import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

import NotFound from '@/modules/public/NotFound.vue'
import Signin from '@/modules/public/Signin/Index.vue'
import SignUp from '@/modules/public/SignUp/Index.vue'
import SignUpContractor from '@/modules/public/SignUp/contractor/Index.vue'
import SignUpCompany from '@/modules/public/SignUp/company/Index.vue'
import SignUpDoctor from '@/modules/public/SignUp/doctor/Index.vue'
import ForgotPassword from '@/modules/public/ForgotPassword/Index.vue'
import ManageInviteDoctor from '@/modules/public/ManageInviteDoctor/Index.vue'
import RecoveryPasswordDoctor from '@/modules/public/RecoveryPasswordDoctor/Index.vue'

import Dashboard from '@/modules/company/Dashboard/Index.vue'
import ManageScale from '@/modules/company/ManageScale/Index.vue'
import Units from '@/modules/company/Units/Index.vue'
import Protocol from '@/modules/company/Units/Protocol/Index.vue'
import Contracts from '@/modules/company/Units/Contracts/Index.vue'
import ScaleSearch from '@/modules/company/ScaleSearch/Index.vue'
import Doctors from '@/modules/company/Doctors/Index.vue'
import Users from '@/modules/company/Users/Index.vue'
import Management from '@/modules/company/Management/Index.vue'
import Profile from '@/modules/company/Profile/Index.vue'
import EditUnit from '@/modules/company/EditUnit/index.vue'
import Consult from '@/modules/company/Consult/index.vue'

import PATHS from './paths'

const isServer = typeof window === 'undefined'

const history = isServer ? createMemoryHistory() : createWebHistory()

const routes = [
  {
    path: PATHS.SIGNIN,
    name: 'signin',
    component: Signin,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.RECOVER_PASSWORD_DOCTOR,
    name: 'recovery-password-doctor',
    component: RecoveryPasswordDoctor,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.FORGOTPASSWORD,
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.MANAGE_DOCTOR_INVITE,
    name: 'convite',
    component: ManageInviteDoctor,
    meta: { layout: 'empty', requiresDoctorUserAuth: true }
  },
  {
    path: PATHS.SIGNUP,
    name: 'signup',
    component: SignUp,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.SIGNUPCONTRACTOR,
    name: 'signup-contractor',
    component: SignUpContractor,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.SIGNUPCOMPANY,
    name: 'signup-company',
    component: SignUpCompany,
    meta: { layout: 'empty' }
  },
  {
    path: PATHS.SIGNUPDOCTOR,
    name: 'signup-doctor',
    component: SignUpDoctor,
    meta: { layout: 'empty' }
  },
  // {
  //   path: PATHS.DESIGN_SYSTEM,
  //   name: 'desing-system',
  //   component: DesignSystem,
  //   meta: { layout: 'empty' }
  // },
  {
    path: PATHS.DASHBOARD,
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.MANAGE_SCALE,
    name: 'gerenciar-escala',
    component: ManageScale,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.UNITS,
    name: 'unidades',
    component: Units,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.CONSULT,
    name: 'consultar',
    component: Consult,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.PROTOCOL,
    name: 'protocolo',
    component: Protocol,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.CONTRACTS,
    name: 'contratos',
    component: Contracts,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.CONSULTSCALE,
    name: 'consulta',
    component: ScaleSearch,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.DOCTORS,
    name: 'medicos',
    component: Doctors,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.USERS,
    name: 'usuarios',
    component: Users,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.MANAGEMENT,
    name: 'gestao',
    component: Management,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.PROFILE,
    name: 'perfil',
    component: Profile,
    meta: { requiresCompanyUserAuth: true }
  },
  {
    path: PATHS.EDIT_HOSPITAL,
    name: 'editar-hospital',
    component: EditUnit,
    meta: { requiresCompanyUserAuth: true }
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
]

export type UserContext = 'company' | 'doctor' | ''

export function setUserContextInLocalStorage(context: UserContext) {
  localStorage.setItem('@ME:user_context', context)
}

function isAuthenticated() {
  const accessToken = localStorage.getItem('@ME:access_token')

  // Verifica se o token de acesso está presente e não está vazio
  return accessToken !== null && accessToken !== ''
}

// function isUserDoctorAuthenticated() {
//   const accessToken = localStorage.getItem('@ME:doctor_access_token')

//   // Verifica se o token de acesso está presente e não está vazio
//   return accessToken !== null && accessToken !== ''
// }

export const router = createRouter({ routes, history })

router.beforeResolve((to, from, next) => {
  console.log('RESOLVE', to)
  if (to.meta.requiresCompanyUserAuth) {
    setUserContextInLocalStorage('company')
    // Verifique se o usuário está autenticado usando a função isAuthenticated
    if (isAuthenticated()) {
      next() // Permitir o acesso à rota privada
    } else {
      next(PATHS.SIGNIN) // Redirecionar para a página pública ou de login
    }
  } else if (to.meta.requiresDoctorUserAuth) {
    setUserContextInLocalStorage('doctor')
    next()
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresCompanyUserAuth)) {
//     setUserContextInLocalStorage('company')
//     // Verifique se o usuário está autenticado usando a função isAuthenticated
//     if (isAuthenticated()) {
//       next() // Permitir o acesso à rota privada
//     } else {
//       next(PATHS.SIGNIN) // Redirecionar para a página pública ou de login
//     }
//   } else if (to.matched.some((record) => record.meta.requiresDoctorUserAuth)) {
//     setUserContextInLocalStorage('doctor')
//     next()
//   } else {
//     next()
//   }
// })
