import { defineStore } from 'pinia'
import Network from '@/Network'
import { CreatePosition } from '@/Network/Types/Requests/PositionRequests'
import {
  ListPositionsResponse,
  PositionResponse
} from '@/Network/Types/Responses/PositionResponses'

export type PositionState = {
  positions: ListPositionsResponse | undefined
  listPositions: PositionResponse[]
}

export const usePositionStore = defineStore('position', {
  state: (): PositionState => ({
    positions: undefined,
    listPositions: []
  }),
  actions: {
    async getAllPositions(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.positions) {
        const data = await Network.position.list()
        this.positions = data
        this.listPositions = this.positions.data
      }
    },
    async create(payload: CreatePosition) {
      await Network.position.create(payload)
    },
    async remove(positionId: number): Promise<void> {
      await Network.schedule.remove(positionId)
    },
    async update({
      positionId,
      payload
    }: {
      positionId: number
      payload: CreatePosition
    }): Promise<void> {
      await Network.position.update(positionId, payload)
    },
    async getByScheduleId(scheduleId: number): Promise<void> {
      const response = await Network.position.getByScheduleId(scheduleId)
      this.listPositions = response.data
    },
    async setPositionsBySchedules(scheduleIds: number[] | null) {
      console.log('this.setPositionsBySchedules', scheduleIds)
      if (!scheduleIds?.length) {
        this.listPositions = []
        return
      }
      await this.getAllPositions()
      const positions =
        this.positions?.data.filter((p) => scheduleIds.includes(p.schedule.id)) || []
      this.listPositions = positions
    }
  }
})
