<template>
  <div
    :style="`width: ${width}; max-width: ${maxWidth}`"
    :class="`${disabled ? 'disabled cursor-not-allowed select-none' : ''}`"
  >
    <div :style="`width: ${width}; max-width: ${maxWidth}`" class="field-wrapper" ref="wrapper">
      <textarea
        v-if="multiline"
        :id="id"
        :name="name"
        :class="isError ? 'hasError resize-none' : 'resize-none'"
        :type="typeInput"
        ref="input"
        @input="onInput"
        @change="onChange"
        v-bind="$attrs"
        :placeholder="placeholder"
        :required="required"
        v-maska="mask"
        rows="3"
        :minlength="min"
        :maxlength="max"
      ></textarea>
      <input
        :disabled="disabled"
        v-if="id && !multiline"
        :id="id"
        :name="name"
        :class="isError ? 'hasError' : ''"
        :type="typeInput"
        ref="input"
        @input="onInput"
        @change="onChange"
        v-bind="$attrs"
        placeholder=""
        :required="required"
        v-maska="mask"
        :minlength="min"
        :maxlength="max"
      />
      <input
        :disabled="disabled"
        v-if="!id && !multiline"
        :name="name"
        :class="isError ? 'hasError' : ''"
        :type="typeInput"
        ref="input"
        @input="onInput"
        @change="onChange"
        v-bind="$attrs"
        placeholder=""
        :required="required"
        v-maska="mask"
        :minlength="min"
        :maxlength="max"
      />
      <!-- <input type="tel"  /> -->

      <div
        :class="`field-placeholder ${multiline ? 'field-placeholder-textarea' : ''}`"
        ref="fieldPlaceholder"
      >
        <span :class="isError ? 'hasErrorLabel' : ''">{{ label }}{{ required ? '*' : '' }}</span>
      </div>
      <button
        v-if="type === 'password' && !state.isShowPassword"
        class="btn-icon"
        @click="showPassword($event, true)"
      >
        <show-icon class="show-icon" width="22" height="15" />
      </button>
      <button
        v-if="type === 'password' && state.isShowPassword"
        class="btn-icon"
        @click="showPassword($event, false)"
      >
        <hide-icon class="hide-icon" width="22" height="22" />
      </button>
    </div>
    <button v-if="withIcon" class="btn-icon" @click="showPassword($event, false)">
      <component v-if="withIcon" :is="icon" width="22" height="22" />
    </button>
    <div v-if="isError" class="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { onMounted, onUpdated, ref, computed, reactive } from 'vue'
import ShowIcon from '@/assets/icons/ShowIcon.vue'
import HideIcon from '@/assets/icons/HideIcon.vue'

export default {
  name: 'TextInput',
  inheritAttrs: false,
  components: {
    ShowIcon,
    HideIcon
  },
  props: {
    id: {
      default: false
    },
    mask: {
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: 'Input Label'
    },
    withIcon: {
      default: false,
      type: Boolean
    },
    icon: Object,
    isError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: 'Alguma coisa está errada'
    },
    max: {
      type: [Boolean, String],
      default: false
    },
    min: {
      type: [Boolean, String],
      default: false
    },
    modelValue: String,
    width: String,
    maxWidth: String,
    required: Boolean,
    name: String,
    multiline: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const fieldPlaceholder = ref(null)
    const wrapper = ref(null)
    const input = ref(null)
    const state = reactive({
      isShowPassword: false
    })

    const typeInput = computed(() => {
      const typeTemp = props.type

      if (props.type !== 'password') {
        return props.type
      }

      if (props.type === 'password' && state.isShowPassword) {
        return 'text'
      }

      if (props.type === 'password' && !state.isShowPassword) {
        return 'password'
      }

      return typeTemp
    })

    function showPassword(evt, param) {
      state.isShowPassword = param
    }

    function onInput(e) {
      context.emit('update:modelValue', e.target.value)
      context.emit('input', e)
    }

    function onChange(e) {
      context.emit('change', e)
    }

    const checkHasValue = () => {
      const inputValue = String(input.value.value).trim()
      if (inputValue) {
        input.value.closest('.field-wrapper').classList.add('hasValue')
      } else {
        input.value.closest('.field-wrapper').classList.remove('hasValue')
      }
    }

    onUpdated(() => checkHasValue())

    onMounted(() => {
      fieldPlaceholder.value.addEventListener('click', () => {
        input.value.focus()
      })

      checkHasValue()

      input.value.addEventListener('keyup', checkHasValue)
      input.value.addEventListener('focusout', checkHasValue)
    })

    return {
      fieldPlaceholder,
      input,
      state,
      showPassword,
      typeInput,
      onInput,
      onChange,
      wrapper
    }
  }
}
</script>

<style scoped>
.field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.field-wrapper input,
.field-wrapper textarea {
  /* border: 1px solid #dadce0; */
  border-width: 1px;
  border-style: solid;
  border-color: theme('colors.neutral50');
  padding: 13px;
  border-radius: 4px;
  width: 100%;
}

.field-wrapper input:focus,
.field-wrapper textarea:focus {
  border-width: 2px;
  border-style: solid;
  border-color: theme('colors.primary20');
  outline: none;
}

.field-wrapper .field-placeholder {
  font-size: 16px;
  position: absolute;
  /* background: #fff; */
  bottom: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 8px;
  padding: 0 8px;
  -webkit-transition:
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition:
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;

  text-align: left;
  width: 100%;
}

.field-placeholder-textarea {
  top: 0px;
}

.field-wrapper .field-placeholder span {
  background: #ffffff;
  padding: 0px 8px;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder,
.field-wrapper textarea:not([disabled]):focus ~ .field-placeholder {
  color: theme('colors.primary20') !important;
}

.field-wrapper input:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
  -webkit-transform: scale(0.75) translateY(-39px) translateX(-60px);
  transform: scale(0.75) translateY(-39px) translateX(-60px);
}

.field-wrapper textarea:not([disabled]):focus ~ .field-placeholder,
.field-wrapper.hasValue textarea:not([disabled]) ~ .field-placeholder {
  -webkit-transform: scale(0.75) translateY(-28px) translateX(-60px);
  transform: scale(0.75) translateY(-28px) translateX(-60px);
}

.btn-icon {
  z-index: 20;
}

.show-icon {
  margin-left: -40px;
  cursor: pointer;
}

.show-icon:hover {
  transform: scale(1.1);
}

.hide-icon {
  margin-left: -41px;
  margin-bottom: 7px;
  cursor: pointer;
}

.hide-icon:hover {
  transform: scale(1.1);
}

.hasError {
  border-color: theme('colors.negative') !important;
  border-width: 2px !important;
}

.hasErrorLabel {
  color: theme('colors.negative') !important;
}

.error {
  font-size: 0.8rem;
  text-align: left;
  margin-left: 8px;
  color: theme('colors.negative');
}
</style>
