import GetRequest from './GetRequest'
import PostRequest from './PostRequest'
import DeleteRequest from './DeleteRequest'
import PatchRequest from './PatchRequest'
import PutRequest from './PutRequest'

export default {
  get: async <R>(
    url: string,
    params?: any | undefined,
    headers?: any | undefined,
    responseType?: any | undefined
  ) => {
    const getRequest = new GetRequest(url, params, headers, responseType)
    return getRequest.run<R>()
  },
  post: async <R>(url: string, body: any, headers?: Record<string, string> | undefined) => {
    const postRequest = new PostRequest(url, body, headers)
    return postRequest.run<R>()
  },
  put: async <R>(url: string, body: any, headers?: Record<string, string> | undefined) => {
    const putRequest = new PutRequest(url, body, headers)
    return putRequest.run<R>()
  },
  patch: async (
    url: string,
    body: any,
    headers?: Record<string, string> | undefined,
    params?: any | undefined
  ) => {
    const patchRequest = new PatchRequest(url, body, headers, params)
    return patchRequest.run()
  },
  delete: async (url: string, headers?: any) => {
    const deleteRequest = new DeleteRequest(url, headers)
    return deleteRequest.run()
  }
}
