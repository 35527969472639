<!-- src/components/icons/chair-icon.vue -->
<template>
  <svg
    :width="width"
    :height="height"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-29.3 -29.3 351.58 351.58"
    xml:space="preserve"
    fill="#000000"
    stroke="#000000"
    stroke-width="0.0029297800000000003"
    transform="rotate(0)"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="#CCCCCC"
      stroke-width="1.171912"
    >
      <g>
        <path
          style="fill: #fafaf9"
          d="M233.956,190.817H59.02v-19.265c0-10.07,8.163-18.233,18.233-18.233h138.47 c10.07,0,18.233,8.163,18.233,18.233V190.817z"
        ></path>
        <path
          style="fill: #fafaf9"
          d="M212.425,97.386H80.551V30.442c0-12.671,10.272-22.943,22.943-22.943h85.988 c12.671,0,22.943,10.272,22.943,22.943V97.386z"
        ></path>
        <rect x="59.02" y="190.817" style="fill: #52526f" width="27.205" height="94.661"></rect>
        <rect x="206.753" y="190.817" style="fill: #52526f" width="27.205" height="94.661"></rect>
        <rect x="80.551" y="97.386" style="fill: #52526f" width="27.205" height="55.933"></rect>
        <rect x="185.22" y="97.386" style="fill: #52526f" width="27.205" height="55.933"></rect>
        <path
          style="fill: #52526f"
          d="M219.925,146.168c0-7.145,0-112.281,0-115.726C219.925,13.656,206.268,0,189.482,0h-85.988 C86.708,0,73.051,13.656,73.051,30.442c0,3.439,0,108.583,0,115.726c-12.197,2.012-21.531,12.626-21.531,25.384 c0,24.397,0,90.382,0,113.926c0,4.142,3.358,7.5,7.5,7.5h27.205c4.142,0,7.5-3.358,7.5-7.5v-39.831h105.527v39.831 c0,4.142,3.357,7.5,7.5,7.5h27.205c4.143,0,7.5-3.358,7.5-7.5c0-23.562,0-89.628,0-113.926 C241.457,158.794,232.122,148.18,219.925,146.168z M115.257,145.819v-40.934h62.464v40.934H115.257z M88.051,104.886h12.205v40.934 H88.051V104.886z M192.72,104.886h12.204v40.934H192.72V104.886z M88.051,30.442c0-8.515,6.928-15.442,15.443-15.442h85.988 c8.515,0,15.442,6.927,15.442,15.442v59.443c-55.862,0-60.944,0-116.874,0V30.442H88.051z M66.52,171.552 c0-5.918,4.815-10.733,10.733-10.733c15.551,0,123.275,0,138.471,0c5.918,0,10.733,4.815,10.733,10.733v11.765 c-1.449,0-158.493,0-159.937,0C66.521,183.317,66.521,171.552,66.52,171.552z M78.724,277.979H66.52v-79.661h12.205v79.661H78.724z M93.724,230.648v-32.331h105.527v32.331L93.724,230.648L93.724,230.648z M226.457,277.979h-12.205v-79.661h12.205V277.979z"
        ></path>
      </g>
    </g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          style="fill: #fafaf9"
          d="M233.956,190.817H59.02v-19.265c0-10.07,8.163-18.233,18.233-18.233h138.47 c10.07,0,18.233,8.163,18.233,18.233V190.817z"
        ></path>
        <path
          style="fill: #fafaf9"
          d="M212.425,97.386H80.551V30.442c0-12.671,10.272-22.943,22.943-22.943h85.988 c12.671,0,22.943,10.272,22.943,22.943V97.386z"
        ></path>
        <rect x="59.02" y="190.817" style="fill: #52526f" width="27.205" height="94.661"></rect>
        <rect x="206.753" y="190.817" style="fill: #52526f" width="27.205" height="94.661"></rect>
        <rect x="80.551" y="97.386" style="fill: #52526f" width="27.205" height="55.933"></rect>
        <rect x="185.22" y="97.386" style="fill: #52526f" width="27.205" height="55.933"></rect>
        <path
          style="fill: #52526f"
          d="M219.925,146.168c0-7.145,0-112.281,0-115.726C219.925,13.656,206.268,0,189.482,0h-85.988 C86.708,0,73.051,13.656,73.051,30.442c0,3.439,0,108.583,0,115.726c-12.197,2.012-21.531,12.626-21.531,25.384 c0,24.397,0,90.382,0,113.926c0,4.142,3.358,7.5,7.5,7.5h27.205c4.142,0,7.5-3.358,7.5-7.5v-39.831h105.527v39.831 c0,4.142,3.357,7.5,7.5,7.5h27.205c4.143,0,7.5-3.358,7.5-7.5c0-23.562,0-89.628,0-113.926 C241.457,158.794,232.122,148.18,219.925,146.168z M115.257,145.819v-40.934h62.464v40.934H115.257z M88.051,104.886h12.205v40.934 H88.051V104.886z M192.72,104.886h12.204v40.934H192.72V104.886z M88.051,30.442c0-8.515,6.928-15.442,15.443-15.442h85.988 c8.515,0,15.442,6.927,15.442,15.442v59.443c-55.862,0-60.944,0-116.874,0V30.442H88.051z M66.52,171.552 c0-5.918,4.815-10.733,10.733-10.733c15.551,0,123.275,0,138.471,0c5.918,0,10.733,4.815,10.733,10.733v11.765 c-1.449,0-158.493,0-159.937,0C66.521,183.317,66.521,171.552,66.52,171.552z M78.724,277.979H66.52v-79.661h12.205v79.661H78.724z M93.724,230.648v-32.331h105.527v32.331L93.724,230.648L93.724,230.648z M226.457,277.979h-12.205v-79.661h12.205V277.979z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ChairIcon',
  props: {
    color: {
      type: String,
      default: '#52526F' // Cor padrão do ícone
    },
    width: {
      type: String,
      default: '24' // Largura padrão
    },
    height: {
      type: String,
      default: '24' // Altura padrão
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
