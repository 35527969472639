<template>
  <div>
    <Disclosure as="div" v-slot="{ open }">
      <div
        :class="`grid lg:grid-cols-12 grid-cols-6 gap-1 w-full h-full items-center py-5 my-0 ${
          open ? '' : 'border-b border-gray-200'
        }`"
      >
        <div class="lg:col-span-1 md:col-span-1 col-span-1">
          <v-avatar color="primary" class="mr-2">
            <span class="text-md font-bold">{{
              getInitalsName(props.data.user.name || 'ME')
            }}</span>
          </v-avatar>
        </div>
        <div class="lg:col-span-2 md:col-span-3 col-span-4">
          <p class="text-left ml-2 md:ml-0">{{ props.data.user?.name }}</p>
        </div>
        <div class="lg:block hidden lg:col-span-3">
          <p class="truncate ...">{{ props.data.user?.email }}</p>
        </div>
        <div class="lg:block hidden lg:col-span-2">
          <p>{{ props.data.user.mainPhone?.phoneNumber }}</p>
        </div>
        <div class="lg:block hidden lg:col-span-2">
          <TableChips :items="props.data.companies" />
        </div>
        <!-- <div class="col-span-1 lg:block hidden"></div> -->
        <div class="lg:col-span-1 col-span-1">
          <div class="flex justify-end gap-4 items-center">
            <DisclosureButton
              as="svg"
              class="cursor-pointer"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M1.49875 14.4571L12.0587 3.89711L15.1016 6.94L4.54164 17.5H1.49875V14.4571ZM16.0152 0.643553L18.3552 2.98355C18.5499 3.17829 18.5499 3.49171 18.3552 3.68645L16.8787 5.16289L13.8359 2.12L15.3123 0.643553C15.4069 0.548924 15.5291 0.5 15.6587 0.5C15.7978 0.5 15.9169 0.545225 16.0152 0.643553Z"
                stroke="#0043CE"
                stroke-linejoin="round"
              />
            </DisclosureButton>
            <!-- <svg
              @click="() => (modalState = true)"
              class="cursor-pointer"
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.400015 3.33285C0.400015 2.81738 0.817883 2.39951 1.33335 2.39951H12.6667C13.1821 2.39951 13.6 2.81738 13.6 3.33285V3.66618C13.6 4.18164 13.1821 4.59951 12.6667 4.59951H1.33335C0.817883 4.59951 0.400015 4.18164 0.400015 3.66618V3.33285Z"
                stroke="#0043CE"
                stroke-width="0.8"
              />
              <path
                d="M4.40002 1.33285C4.40002 0.817379 4.81788 0.399512 5.33335 0.399512H8.66668C9.18215 0.399512 9.60002 0.817379 9.60002 1.33285V2.59951H4.40002V1.33285Z"
                stroke="#0043CE"
                stroke-width="0.8"
              />
              <mask id="path-3-inside-1_1468_2925" fill="white">
                <path
                  d="M13 5.99951H1V17.9995C1 19.0995 1.9 19.9995 3 19.9995H11C12.1 19.9995 13 19.0995 13 17.9995V5.99951Z"
                />
              </mask>
              <path
                d="M13 5.99951H13.8V5.19951H13V5.99951ZM1 5.99951V5.19951H0.2V5.99951H1ZM13 5.19951H1V6.79951H13V5.19951ZM0.2 5.99951V17.9995H1.8V5.99951H0.2ZM0.2 17.9995C0.2 19.5413 1.45817 20.7995 3 20.7995V19.1995C2.34183 19.1995 1.8 18.6577 1.8 17.9995H0.2ZM3 20.7995H11V19.1995H3V20.7995ZM11 20.7995C12.5418 20.7995 13.8 19.5413 13.8 17.9995H12.2C12.2 18.6577 11.6582 19.1995 11 19.1995V20.7995ZM13.8 17.9995V5.99951H12.2V17.9995H13.8Z"
                fill="#0043CE"
                mask="url(#path-3-inside-1_1468_2925)"
              />
              <path
                d="M4.4 9.99951C4.4 9.7786 4.22091 9.59951 4 9.59951C3.77909 9.59951 3.6 9.7786 3.6 9.99951H4.4ZM3.6 15.4995C3.6 15.7204 3.77909 15.8995 4 15.8995C4.22091 15.8995 4.4 15.7204 4.4 15.4995H3.6ZM3.6 9.99951V15.4995H4.4V9.99951H3.6Z"
                fill="#0043CE"
              />
              <path
                d="M7.4 9.99951C7.4 9.7786 7.22091 9.59951 7 9.59951C6.77909 9.59951 6.6 9.7786 6.6 9.99951H7.4ZM6.6 15.4995C6.6 15.7204 6.77909 15.8995 7 15.8995C7.22091 15.8995 7.4 15.7204 7.4 15.4995H6.6ZM6.6 9.99951V15.4995H7.4V9.99951H6.6Z"
                fill="#0043CE"
              />
              <path
                d="M10.4 9.99951C10.4 9.7786 10.2209 9.59951 10 9.59951C9.77909 9.59951 9.6 9.7786 9.6 9.99951H10.4ZM9.6 15.4995C9.6 15.7204 9.77909 15.8995 10 15.8995C10.2209 15.8995 10.4 15.7204 10.4 15.4995H9.6ZM9.6 9.99951V15.4995H10.4V9.99951H9.6Z"
                fill="#0043CE"
              />
            </svg> -->
          </div>
        </div>
      </div>
      <DisclosurePanel :class="` w-full py-4 ${open ? 'border-b border-gray-200' : ''}`">
        <div class="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 gap-4 pb-6 my-0">
          <Input
            @change="() => validatePassword()"
            :isError="isErrorPassword"
            v-model="form.newPassword"
            errorMessage="A senha precisa ter pelo menos 8 caracters, 1 letra maíuscula, 1 caracter especial e 1 número"
            type="password"
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
            width="100%"
            label="Senha"
            required
          />
          <Input
            @change="() => validateConfirmPassword()"
            :isError="isErrorConfirmPassword"
            v-model="form.confirmPassword"
            errorMessage="As senhas não coincidem!"
            type="password"
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$"
            width="100%"
            label="Senha"
            required
          />
          <OutlineButton
            @click="handleSendPassword"
            width="100%"
            style="max-height: 52px"
            class="mr-4"
            text="Confirmar alteração de senha"
            :loading="loading"
          />
        </div>
        <!-- <div class="grid lg:flex grid-cols-1 lg:grid-cols-2 gap-0 md:gap-6 my-2">
          <div>
            <p class="text-left font-bold">Escolha da página inicial</p>
            <div class="py-0 my-4 gap-2 md:gap-4 grid md:flex grid-cols-1 md:grid-cols-3">
              <div class="w-max" v-for="option in options" :key="option">
                <input
                  style="accent-color: #2f7f91"
                  type="radio"
                  :id="option + props.data.user.id"
                  :value="option"
                  v-model="form.radio"
                />
                <label class="ml-2 select-none" :for="option + props.data.user.id">{{ option }}</label>
              </div>
            </div>
          </div>
          <div>
            <p class="text-left font-bold">Acesso</p>
            <div class="py-4 flex gap-8 text-left">
              <Checkbox
                v-model:checked="form.checkbox"
                :fieldId="String(props.data.user.id)"
                label="Acesso total (realiza qualquer ação no sistema)"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 lg:hidden gap-4 mb-4 mt-0">
          <OutlineButton
            @click="
              () => {
                cleanForm()
                close()
              }
            "
            border="border-none"
            hover="bg-transparent"
            text="Cancelar"
            width="100%"
          />
          <FilledButton @click="handleSaveEditUser" text="Salvar" width="100%" />
        </div>
        <div class="hidden lg:flex justify-end gap-4 mb-4 mt-0">
          <OutlineButton
            @click="
              () => {
                cleanForm()
                close()
              }
            "
            border="border-none"
            hover="bg-transparent"
            text="Cancelar"
            width="95px"
          />
          <FilledButton @click="handleSaveEditUser" text="Salvar" width="80px" />
        </div> -->
      </DisclosurePanel>
    </Disclosure>
    <Modal
      :modalStateProp="modalState"
      @close="() => (modalState = false)"
      :user="props.data.user"
    />
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineProps, reactive, ref } from 'vue'
// import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
// import Checkbox from '@/components/designSystem/Checkbox/index.vue'
import Modal from '../../../modules/company/Users/components/DeleteUserModal/Index.vue'
import Input from '../Inputs/Input.vue'
import Network from '@/Network'
import { CompanyUserResponse } from '@/Network/Types/Responses/CompanyResponses'
import { useToast } from 'vue-toastification'
import TableChips from '@/components/designSystem/Chips/TableChips.vue'

const props = defineProps<{
  data: CompanyUserResponse
}>()
const modalState = ref(false)
// const options = reactive(['Dashboard', 'Meus hospitais', 'Controle de gastos'])
const isErrorPassword = ref(false)
const isErrorConfirmPassword = ref(false)
const isValid = ref(false)
const loading = ref(false)
const toast = useToast()

const form = reactive({
  radio: 'Dashboard',
  checkbox: false,
  newPassword: '',
  confirmPassword: ''
})

// const cleanForm = () => {
//   form.checkbox = false
//   form.radio = 'Dashboard'
//   form.newPassword = ''
//   form.confirmPassword = ''
// }

// const handleSaveEditUser = async () => {
//   // await Network.company.changeUserCompanyPassword(props.data.user.id, {
//   //   password: form.newPassword,
//   //   confirmPassword: form.confirmPassword
//   // })
// }

function validatePassword() {
  const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/)
  if (form.newPassword === '' || !regex.test(form.newPassword)) {
    isValid.value = false
    isErrorPassword.value = true
  } else {
    isValid.value = true
    isErrorPassword.value = false
  }
}

function validateConfirmPassword() {
  const isEqualPasswords = form.confirmPassword === form.newPassword

  if (form.confirmPassword === '') {
    isValid.value = false
    isErrorConfirmPassword.value = true
  } else if (!isEqualPasswords) {
    isValid.value = false
    isErrorConfirmPassword.value = true
  } else {
    isValid.value = true
    isErrorConfirmPassword.value = false
  }
}

const handleSendPassword = async () => {
  if (form.newPassword.length === 0 || form.confirmPassword.length === 0) return
  try {
    loading.value = true
    await Network.company.changeUserCompanyPassword(props.data.user.id, {
      password: form.newPassword,
      confirmPassword: form.confirmPassword
    })
    toast.success(`Usuário ${props.data.user.name} teve a senha atualziada com sucesso!`)
  } catch (error) {
    toast.error('Ocorreu algum erro, verifique os dados inseridos e tente novamente!')
    console.error(error)
  } finally {
    loading.value = false
  }
}

const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}
</script>
