<template>
  <CreateSchedule @openCreateHospitalModal="openCreateHospitalModal" />
  <CreatePosition @openCreateHospitalModal="openCreateHospitalModal" />
  <ListSchedules
    @removeSchedule="handleRemove"
    @updateSchedule="handleUpdate"
    @showPositions="handleToShowPositions"
  />
  <CreateHospitalModal
    type="create"
    :modalStateProp="showCreateHospitalModal"
    @close="showCreateHospitalModal = false"
  />
  <RemoveScheduleModal :schedule-id="scheduleIdToDelete" />
  <UpdateScheduleModal :schedule="scheduleToUpdate" />
  <PositionsModal :schedule-id="scheduleIdToShowPositions" />
</template>

<script lang="ts">
import { ref } from 'vue'
import CreateSchedule from './CreateSchedule.vue'
import CreatePosition from './CreatePosition.vue'
import ListSchedules from './ListSchedules.vue'
import CreateHospitalModal from '../../../modules/company/Units/modals/health.unit.modal.vue'
import RemoveScheduleModal from './modals/removeSchedule.vue'
import PositionsModal from './modals/positions.vue'
import UpdateScheduleModal from './modals/updateSchedule.vue'
import { ScheduleResponse } from '@/Network/Types/Responses/ScheduleUnitResponses'

export default {
  components: {
    CreateSchedule,
    CreatePosition,
    ListSchedules,
    RemoveScheduleModal,
    PositionsModal,
    UpdateScheduleModal,
    CreateHospitalModal
  },
  setup() {
    const showCreateHospitalModal = ref(false)
    const scheduleIdToDelete = ref<number>(0)
    const scheduleIdToShowPositions = ref<number>(0)
    const scheduleToUpdate = ref<ScheduleResponse>()

    const openCreateHospitalModal = () => {
      showCreateHospitalModal.value = true
    }

    const handleRemove = (id: number) => {
      scheduleIdToDelete.value = id
    }

    const handleToShowPositions = (id: number) => {
      scheduleIdToShowPositions.value = id
    }

    const handleUpdate = (data: ScheduleResponse) => {
      scheduleToUpdate.value = data
    }

    return {
      handleRemove,
      handleUpdate,
      openCreateHospitalModal,
      scheduleIdToDelete,
      scheduleIdToShowPositions,
      scheduleToUpdate,
      handleToShowPositions,
      showCreateHospitalModal
    }
  }
}
</script>

<style></style>
