<!-- src/components/modals/PositionsModal.vue -->
<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]">
    <template #header>
      <p class="font-bold">Posições do Plantão</p>
    </template>
    <div class="flex flex-col gap-2">
      <div v-if="isLoading" class="flex justify-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else>
        <table class="w-full table-fixed">
          <colgroup>
            <col style="width: 70%" />
            <col style="width: 30%" />
          </colgroup>
          <tbody>
            <tr v-for="position in positions" :key="position.id" class="fixed-row border-b">
              <td class="pr-1">
                <template v-if="editingPositionId === position.id">
                  <input
                    v-model="editName"
                    @keyup.enter="saveUpdate(position)"
                    class="border rounded px-2 py-1 text-center"
                    v-focus
                  />
                </template>
                <template v-else>
                  <span>{{ position.name }}</span>
                </template>
              </td>
              <td class="pr-4 text-left">
                <template v-if="editingPositionId === position.id">
                  <template v-if="savingPositionId === position.id">
                    <div
                      class="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50 z-50"
                    >
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </template>
                  <template v-else>
                    <save-disk-icon
                      @click.stop="saveUpdate(position)"
                      class="cursor-pointer"
                      width="24"
                      height="24"
                      :style="{ pointerEvents: isLoading ? 'none' : 'auto' }"
                      aria-label="Salvar nome da posição"
                      title="Salvar"
                    />
                  </template>
                </template>
                <template v-else>
                  <edit-icon
                    @click.stop="handleUpdate(position)"
                    class="cursor-pointer text-blue-500 hover:text-blue-700"
                  />
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="positions.length === 0" class="text-gray-500 text-center mt-4">
          Nenhuma posição disponível para este plantão.
        </p>
      </div>
      <div v-if="error" class="text-red-500">{{ error }}</div>
    </div>
    <template #footer>
      <div class="flex justify-end">
        <outline-button width="119px" @click="handleToggleModal" text="Fechar" />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { ref, watch, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import Network from '@/Network'
import EditIcon from '@/assets/icons/edit.vue'
import SaveDiskIcon from '@/assets/icons/save-icon.vue'
import { useToast } from 'vue-toastification'

//eslint-disable-next-line vue/no-unused-components
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'

//eslint-disable-next-line vue/no-unused-components
import Spinner from '@/components/others/Spinner.vue'

export type PositionResponse = {
  id: number
  name: string
  scheduleId: number
}

export type ListPositionsResponse = {
  data: PositionResponse[]
}

interface ApiPosition {
  id: number
  name: string
  schedule: {
    id: number
  }
}

export default {
  components: {
    ModalVue,
    OutlineButton,
    EditIcon,
    SaveDiskIcon,
    //eslint-disable-next-line vue/no-unused-components
    GenericInput,
    //eslint-disable-next-line vue/no-unused-components
    Spinner
  },
  directives: {
    focus: {
      mounted(el: HTMLElement) {
        el.focus()
      }
    }
  },
  props: {
    scheduleId: { required: true, type: Number }
  },
  setup(props) {
    const modalName = EModals.POSITIONS_MODAL
    const modalStore = useModalStore()
    const { modals } = storeToRefs(modalStore)

    const positions = ref<PositionResponse[]>([])
    const isLoading = ref(false)
    const error = ref<string | null>(null)
    const savingPositionId = ref<number | null>(null)

    const editingPositionId = ref<number | null>(null)
    const editName = ref<string>('')

    const toast = useToast()

    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }

    const fetchPositions = async () => {
      if (!props.scheduleId) return
      isLoading.value = true
      error.value = null
      try {
        const apiResponse = await Network.position.getByScheduleId(props.scheduleId)
        const apiPositions = apiResponse.data as ApiPosition[]
        const mappedPositions: PositionResponse[] = apiPositions.map((pos) => ({
          id: pos.id,
          name: pos.name,
          scheduleId: pos.schedule.id
        }))
        positions.value = mappedPositions
      } catch (err) {
        error.value = 'Erro ao carregar as posições.'
        console.error(err)
      } finally {
        isLoading.value = false
      }
    }

    const displayedPositions = computed((): PositionResponse[] => {
      return positions.value
    })

    watch(
      () => modals.value[modalName],
      (isActive) => {
        if (isActive) {
          fetchPositions()
          console.log('list ', positions.value)
        } else {
          positions.value = []
          error.value = null
          editingPositionId.value = null
          editName.value = ''
        }
      },
      { immediate: false }
    )

    const handleUpdate = (position: PositionResponse) => {
      editingPositionId.value = position.id
      editName.value = position.name
    }

    const saveUpdate = async (position: PositionResponse) => {
      if (editName.value.trim() !== '') {
        const index = positions.value.findIndex((p) => p.id === position.id)
        if (index !== -1) {
          const oldName = positions.value[index].name
          positions.value[index].name = editName.value
          savingPositionId.value = position.id
          try {
            await Network.position.update(position.id, {
              name: editName.value,
              scheduleId: props.scheduleId
            })
            toast.success(`Nome atualizado para: ${editName.value}`)
            console.log('Nome atualizado para:', editName.value)
          } catch (error) {
            positions.value[index].name = oldName
            toast.error('Erro ao atualizar o nome da posição.')
            console.error('Erro ao atualizar o nome:', error)
          } finally {
            savingPositionId.value = null
          }
        }
      }
      editingPositionId.value = null
      editName.value = ''
    }

    return {
      modalName,
      modals,
      positions,
      isLoading,
      error,
      handleToggleModal,
      displayedPositions,
      handleUpdate,
      saveUpdate,
      editingPositionId,
      savingPositionId,
      editName
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-blue-500:hover {
  color: #2b6cb0;
}

.text-red-500:hover {
  color: #c53030;
}

.td-flex {
  display: flex;
  align-items: center;
}

.td-flex span {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  height: 28px;
  box-sizing: border-box;
  flex-grow: 1;
}

.fixed-row {
  height: 40px;
  padding: 20px 1;
}

.save-disk-icon,
.edit-icon {
  flex-shrink: 0;
}

.input-icon-wrapper {
  display: flex;
  align-items: center;
  width: 10%;
}
</style>
