<template>
  <v-row class="bg-gray-50 text-sm text-gray-500 hidden md:flex">
    <v-col cols="12" v-if="isLoadingGetAllGroups">
      <v-col class="text-subtitle-1 text-center" cols="12"> Buscando Grupos... </v-col>
      <v-progress-linear color="primary" height="6" indeterminate rounded />
    </v-col>
    <v-col cols="12" md="3" class="md:text-start"> Nome </v-col>
    <v-col cols="12" md="3" class="md:text-start"> Email </v-col>
    <v-col cols="12" md="2" class="md:text-start"> Empresa </v-col>
    <v-col cols="12" md="3" class="md:text-start"> Usuários </v-col>
    <v-col cols="12" md="1" class="md:text-end"> Ações </v-col>
  </v-row>
  <v-row
    class="border-b-2 items-center hover:bg-gray-50 text-gray-600"
    style="min-height: 95px"
    v-for="item in filteredGroups"
    :key="item.id"
  >
    <v-col cols="12" md="3" class="md:text-start">
      {{ item.name }}
    </v-col>

    <v-col cols="12" md="3" class="break-words md:text-start">
      {{ item.email }}
    </v-col>
    <v-col cols="12" md="2" class="md:text-start">
      {{ item.company.name }}
    </v-col>
    <v-col cols="12" md="3">
      <TableChips :items="item.userGroups.map(({ user }) => ({ id: user.id, name: user.name }))" />
    </v-col>
    <v-col cols="12" md="1">
      <div class="flex gap-2 items-center justify-center md:justify-end">
        <v-icon
          @click="openModalUpdate(item)"
          class="share-icon hover:cursor-pointer"
          color="primary"
          size="small"
          :icon="mdiPencil"
        />
        <v-icon
          @click="openModalDelete(item.id, item.name)"
          class="share-icon hover:cursor-pointer"
          color="primary"
          size="small"
          :icon="mdiTrashCan"
        />
      </div>
    </v-col>
  </v-row>
  <teleport to="#app">
    <ConfirmDeleteModal
      @closeModal="showDeleteModal = false"
      :show="showDeleteModal"
      :isLoading="isLoadingDelete"
      @handleConfirm="handleDelete"
      :description="`Você tem certeza que deseja excluir o grupo ${groupToDelete.name}?`"
    />
    <UpdateGroupModal :group="groupToUpdate" />
  </teleport>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import { useGroupStore } from '@/pinia-store/useGroupStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import ConfirmDeleteModal from '@/components/designSystem/modals/confirm.delete.action.vue'
import TableChips from '@/components/designSystem/Chips/TableChips.vue'
import { DEFAULT_MESSAGES } from '@/hooks/useCommon'
import { GroupResponse } from '@/Network/Types/Responses/GroupResponses'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import UpdateGroupModal from './modal/update.group.modal.vue'
const groupStore = useGroupStore()
const { filteredGroups, isLoadingGetAllGroups } = storeToRefs(groupStore)
const isLoadingDelete = ref(false)
const showDeleteModal = ref(false)
const groupToDelete = ref<{ name: string; id: number }>({ id: 0, name: '' })
const groupToUpdate = ref<GroupResponse | undefined>(undefined)
const toast = useToast()

onMounted(async () => {
  await groupStore.getAllGroups()
})

const openModalDelete = (id: number, name: string) => {
  showDeleteModal.value = true
  groupToDelete.value.id = id
  groupToDelete.value.name = name
}
const handleDelete = async () => {
  try {
    isLoadingDelete.value = true
    await groupStore.remove(groupToDelete.value.id)
    toast.success('Grupo removido com sucesso!')
    await groupStore.getAllGroups(true)
  } catch (error) {
    toast.error(DEFAULT_MESSAGES.error)
  } finally {
    showDeleteModal.value = false
    isLoadingDelete.value = false
    groupToDelete.value = {
      id: 0,
      name: ''
    }
  }
}

const modalStore = useModalStore()
const openModalUpdate = (group: GroupResponse) => {
  groupToUpdate.value = group
  modalStore.toggleModal(EModals.UPDATE_GROUP_MODAL)
}
</script>
