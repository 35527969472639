import { defineStore } from 'pinia'
import Network from '@/Network'
import { ListCompanyUserResponse } from '@/Network/Types/Responses/CompanyResponses'

export type UserState = {
  users: ListCompanyUserResponse | undefined
  listUsers: { title: string; subtitle: string; value: string }[]
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    users: undefined,
    listUsers: []
  }),
  actions: {
    async getAllUsers(forceReload: boolean = false): Promise<void> {
      if (forceReload || !this.users) {
        const { count, data } = await Network.user.getAllUsers()
        this.users = { data, count }
        this.listUsers = data.map(({ user }) => ({
          title: user.name,
          subtitle: user.email,
          value: String(user.id)
        }))
      }
    }
  }
})
