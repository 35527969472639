<template>
  <div class="container-login w-full h-screen">
    <div class="logo flex justify-center">
      <Logo width="160px" logo="full" />
    </div>
    <div class="flex justify-center">
      <div
        class="card flex items-center justify-center flex-col rounded overflow-hidden shadow-lg gap-4"
      >
        <InvalidTokenPage v-if="invalidToken" />
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          :size="128"
          :width="7"
          color="primary"
        ></v-progress-circular>
        <div v-else-if="!invalidToken">
          <!-- Steps para médicos que precisam se registrar -->
          <div v-if="!existUser">
            <div class="flex flex-col gap-2 items-center justify-between">
              <h1 class="title">Olá, {{ doctorCrm.name }}!</h1>
              <div class="invite-content text-center leading-7">
                Nós da
                <span
                  class="text-bold underline decoration-2 decoration-primary"
                  id="nomeEmpresa"
                  >{{ companyName }}</span
                >
                convidamos você para se juntar ao aplicativo Minha Escala.<br />
                <div class="mt-4 mb-2">
                  Verifiquei aqui e você ainda não possui uma conta no aplicativo, por favor
                  cadastre-se para poder aceitar o convite da nossa empresa.
                </div>
              </div>
              <div class="register flex flex-col items-center">
                <OutlineButton
                  @click="openSignupModal"
                  :disabled="!isConfirmedCrm"
                  text="Registar meu usuário"
                >
                  Registar meu usuário
                </OutlineButton>
                <v-checkbox
                  class="confirm-checkbox"
                  density="compact"
                  color="primary"
                  v-model="isConfirmedCrm"
                >
                  <template v-slot:label>
                    Confirmo, que sou portador do&nbsp;<strong>CRM-UF:</strong>&nbsp;
                    <strong>{{ `${doctorCrm.number}-${doctorCrm.uf}` }}</strong>
                    <v-tooltip v-if="!isConfirmedCrm" activator="parent" location="top"
                      >Por favor confirme que é o portador do CRM</v-tooltip
                    >
                  </template>
                </v-checkbox>

                <!-- </router-link> -->
              </div>
            </div>
          </div>
          <!-- Steps para médicos com usuario existente e não logados -->
          <div v-if="existUser && !isAuthenticated()">
            <div class="flex flex-col gap-2 items-center">
              <h1 class="title">Dr(a) acesse sua conta</h1>
              <h5 class="subtitle">Para visualizar o convite por favor acesse sua conta!</h5>
              <v-form
                :fast-fail="false"
                class="flex pt-4 items-center flex-col rounded overflow-hidden"
                ref="elLoginForm"
              >
                <div class="input w-80 mt-3">
                  <GenericInput
                    type="email"
                    label="Email"
                    v-model="loginForm.values.email"
                    @validate="validate('email')"
                    :rules="[rules.email]"
                  />
                </div>
                <div class="input w-80">
                  <PasswordInput
                    v-model="loginForm.values.password"
                    @validate="validate('password')"
                    :rules="[rules.password]"
                  />
                </div>
                <div class="recover-password">
                  <router-link to="/forgot-password">
                    <GhostButton text="Esqueci minha senha" />
                  </router-link>
                </div>
                <div class="login m-3">
                  <FilledButton @click="submitLogin" text="Entrar" />
                </div>
              </v-form>
            </div>
          </div>
          <!-- Steps para médicos com usuario existente e já logados -->
          <div v-if="existUser && isAuthenticated()">
            <!-- Tela de responder ao convite! -->
            <div
              v-if="!isCompanyDoctorInviteAcceptSucces && !isCompanyDoctorInviteRefuseSucces"
              class="flex flex-col gap-2 items-center"
            >
              <h1 class="title">Olá, {{ doctorCrm.name }}!</h1>
              <div class="invite-content text-center leading-7">
                Nós da
                <span
                  class="text-bold underline decoration-2 decoration-primary"
                  id="nomeEmpresa"
                  >{{ companyName }}</span
                >
                convidamos você para se juntar ao nosso time no aplicativo Minha Escala.<br />
              </div>
              <div class="flex items-center">
                <div>
                  <FilledButton
                    @click="refuseInvite"
                    color="error"
                    width="200px"
                    text="Recusar Convite"
                  />
                </div>
                <div class="login m-3">
                  <FilledButton @click="acceptInvite" width="200px" text="Aceitar convite" />
                </div>
              </div>
            </div>
            <!-- Tela de convite aceito ou recusado com sucesso! -->
            <div v-if="isCompanyDoctorInviteAcceptSucces || isCompanyDoctorInviteRefuseSucces">
              <h1 v-if="isCompanyDoctorInviteAcceptSucces" class="title">
                Convite foi aceito com sucesso!
              </h1>
              <h1 v-else-if="isCompanyDoctorInviteRefuseSucces" class="title">
                Convite foi recusado com sucesso!
              </h1>
              <h5 class="subtitle">Clique para abrir o aplicativo!</h5>
              <div class="flex gap-2 mt-5">
                <a :href="androidUrl" target="_blank" rel="noopener noreferrer">
                  <div
                    class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3 cursor-pointer"
                  >
                    <img width="150" height="33px" src="/google play.png" />
                  </div>
                </a>
                <a :href="iosUrl" target="_blank" rel="noopener noreferrer">
                  <div class="w-[175px] h-[60px] bg-white border rounded border-gray-500 p-3">
                    <img width="150" height="33" src="/app_store.png" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DoctorSignupModal @onSuccessSubmit="onSuccessSubmitSignup" />
    <ConfirmAlert
      v-if="showDialogAccept"
      :show-alert="showDialogAccept"
      @onConfirm="onConfirmAccept"
      @onCancel="onCancelAccept"
    />
    <ConfirmAlert
      v-if="showDialogRefuse"
      :show-alert="showDialogRefuse"
      @onConfirm="onConfirmRefuse"
      @onCancel="onCancelRefuse"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, reactive } from 'vue'
import Logo from '@/components/designSystem/Logo/index.vue'
import { useRoute } from 'vue-router'
import InvalidTokenPage from './components/invalidTokenPage.vue'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import { schemaValidate } from '@/utils/yup'
import { states, LoginSchema } from '@/modules/public/Signin/schemas'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import PasswordInput from '@/components/designSystem/Inputs/PasswordInput.vue'
import ConfirmAlert from '@/components/designSystem/Alerts/ConfirmAlert.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import DoctorSignupModal from '@/modules/public/ManageInviteDoctor/modals/doctor.signup.modal.vue'
import { VForm } from 'vuetify/lib/components/index.mjs'
import { useAuthStore } from '@/pinia-store/useAuthStore'
import { CompanyDoctorInviteStatusEnum } from '@/Network/Types/Requests/CompanyRequests'

const toast = useToast()
const modalStore = useModalStore()
const authStore = useAuthStore()

const token = ref('')
const invalidToken = ref(false)
const existUser = ref(false)
const companyName = ref('')
const androidUrl = ref('#')
const iosUrl = ref('#')
const companyDoctorInviteId = ref(0)
const isCompanyDoctorInviteAcceptSucces = ref(false)
const isCompanyDoctorInviteRefuseSucces = ref(false)
const doctorCrm = reactive({ name: '', number: '', uf: '' })
const isLoading = ref(false)
const isConfirmedCrm = ref(false)
const showDialogAccept = ref(false)
const showDialogRefuse = ref(false)

const route = useRoute()

const loginForm = reactive(states)
const elLoginForm = ref<typeof VForm | null>(null)

const isAuthenticated = () => {
  if (existUser.value) {
    const accessToken = localStorage.getItem('@ME:doctor_access_token')

    // Verifica se o token de acesso está presente e não está vazio
    return accessToken !== null && accessToken !== ''
  }
  return false
}

const rules = reactive({
  email: () => {
    return !loginForm.errors.email || loginForm.errors.email
  },
  password: () => {
    return !loginForm.errors.password || loginForm.errors.password
  }
})

const validate = async (field: string) => {
  await schemaValidate(field, loginForm, LoginSchema)
}

const openSignupModal = () => {
  modalStore.toggleModal(EModals.DOCTOR_SIGNUP)
}

const acceptInvite = () => {
  showDialogAccept.value = true
}

const refuseInvite = () => {
  showDialogRefuse.value = true
}

const onConfirmAccept = async () => {
  try {
    toast.info('Aceitando convite...')

    const storesUrls = await Network.doctor.sendResponseToCompanyDoctorInvite({
      companyDoctorInviteId: companyDoctorInviteId.value,
      accept: true
    })
    androidUrl.value = storesUrls.androidUrl
    iosUrl.value = storesUrls.iosUrl
    isCompanyDoctorInviteAcceptSucces.value = true
    toast.success('Convite aceito com sucesso!')
  } catch (e) {
    console.error(e)
    toast.error(
      'Ocorreu um erro ao responder o convite, por favor tente novamente ou entre em contato conosco.'
    )
  } finally {
    showDialogAccept.value = false
  }
}

const onCancelAccept = () => {
  showDialogAccept.value = false
}

const onConfirmRefuse = async () => {
  try {
    toast.info('Recusando convite...')

    const storesUrls = await Network.doctor.sendResponseToCompanyDoctorInvite({
      companyDoctorInviteId: companyDoctorInviteId.value,
      accept: false
    })

    androidUrl.value = storesUrls.androidUrl
    iosUrl.value = storesUrls.iosUrl
    isCompanyDoctorInviteRefuseSucces.value = true
    toast.success('Convite recusado com sucesso!')
  } catch (e) {
    toast.error(
      'Ocorreu um erro ao responder o convite, por favor tente novamente ou entre em contato conosco.'
    )
    console.error(e)
  } finally {
    showDialogRefuse.value = false
  }
}

const onCancelRefuse = () => {
  showDialogRefuse.value = false
}

const getCompanyDoctorInviteData = async () => {
  try {
    isLoading.value = true
    if (!route.query['token']) {
      invalidToken.value = true
      return
    }
    token.value = String(route.query['token'])
    const { data: doctorCompanyInvite } = await Network.doctors.validateTokenCompanyDoctorInvite(
      btoa(token.value)
    )

    if (doctorCompanyInvite.company.name) {
      companyName.value = doctorCompanyInvite.company.name
    }

    if (doctorCompanyInvite.id) {
      companyDoctorInviteId.value = doctorCompanyInvite.id
    }

    if (doctorCompanyInvite.doctor.mainCrm) {
      doctorCrm.name = doctorCompanyInvite.doctor.mainCrm.name
      doctorCrm.number = doctorCompanyInvite.doctor.mainCrm.number
      doctorCrm.uf = doctorCompanyInvite.doctor.mainCrm.uf
    }

    if (doctorCompanyInvite.doctor.user) {
      existUser.value = true
    }

    if (doctorCompanyInvite.doctorStatus) {
      const status: CompanyDoctorInviteStatusEnum = doctorCompanyInvite.doctorStatus
      if (status === CompanyDoctorInviteStatusEnum.ACCEPTED) {
        isCompanyDoctorInviteAcceptSucces.value = true
      }
      if (status === CompanyDoctorInviteStatusEnum.DECLINED) {
        isCompanyDoctorInviteRefuseSucces.value = true
      }
    }
  } catch {
    toast.error('Token inválido ou expirado')
    token.value = ''
    invalidToken.value = true
  } finally {
    isLoading.value = false
  }
}

const onSuccessSubmitSignup = async () => {
  await getCompanyDoctorInviteData()
}

onBeforeMount(async () => {
  await getCompanyDoctorInviteData()
})

async function submitLogin() {
  // const isValidForm = await schemaValidateAllFields(loginForm, LoginSchema)
  try {
    isLoading.value = true
    await authStore.loginDoctor({
      email: loginForm.values.email || '',
      password: loginForm.values.password || ''
    })
  } catch {
    console.error('Erro ao realizar login')
  } finally {
    isLoading.value = false
    await getCompanyDoctorInviteData()
  }
}
</script>

<style scoped>
.confirm-checkbox >>> .v-label {
  font-family: 'Inter';
  font-size: 14px !important;
}
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2f7f91;
}
.card {
  width: 510px;
  height: 100%;
  padding: 3rem;
  padding-bottom: 2rem;
  background-color: #fff;
}

@media (max-width: 556px) {
  .card {
    width: 90%;
    background-color: #fff;
  }
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.invite-content {
  font-family: 'Inter';
  max-width: 80%;
}

.subtitle {
  font-family: 'Inter';
  font-style: normal;
  max-width: 80%;
}
.logo {
  padding-bottom: 3rem;
}
</style>
