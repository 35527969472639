<template>
  <div
    class="sm:border-2 sm:border-[#F0F0F4] border-[0px] shadow-md sm:shadow-none rounded-2xl overflow-hidden min-h-[144px] w-full"
  >
    <div class="flex gap-2 sm:max-w-xs max-w-full px-2 py-4 text-left">
      <div class="flex shrink-0 w-12 h-12">
        <v-avatar color="primary" class="mr-2">
          <span class="text-md font-bold">{{
            getInitalsName(companyDoctor?.doctor?.user?.name || 'ME')
          }}</span>
        </v-avatar>
        <!-- <img :src="companyDoctor.doctor.user.avatar ||
          'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
          " :alt="companyDoctor.doctor.user.name" class="rounded-full" /> -->
      </div>
      <div class="flex sm:flex-col sm:justify-start justify-between">
        <div class="flex flex-col justify-between sm:inline">
          <div>
            <p
              class="font-bold text-sm whitespace-nowrap text-ellipsis overflow-hidden"
              :title="companyDoctor?.doctor?.user?.name"
            >
              {{ companyDoctor?.doctor?.user?.name }}
            </p>
            <p class="text-sm sm:inline">
              {{ `${companyDoctor.doctor.mainCrm.number}-${companyDoctor.doctor.mainCrm.uf}` }}
            </p>
          </div>
          <div>
            <p class="text-sm capitalize">{{ companyDoctor.company.name.toLowerCase() }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="sm:bg-[#F0F0F4] bg-[#FAFAFA] overflow-hidden rounded-b-xl flex items-center justify-evenly py-1"
    >
      <div
        class="flex items-center gap-1 cursor-pointer select-none px-2 py-1 rounded-md border-[1px] border-transparent hover:border-[1px] hover:border-[#2F7F91] hover:bg-[#D8EEF3]"
        v-for="(option, index) in options"
        :key="index"
        @click="option.action"
      >
        <img draggable="false" :src="option.icon" :alt="option.label" />
        <p class="text-xs">{{ option.label }}</p>
      </div>
    </div> -->
  </div>
  <!-- <ProfileModal
    :modalStateProp="profileModalState"
    :doctor="doctor"
    @close="() => (profileModalState = false)"
  /> -->
  <!-- <DutyModal
    :modalStateProp="dutyModalState"
    :doctor="doctor"
    @close="() => (dutyModalState = false)"
  /> -->
  <AlertModal :modalStateProp="alertModalState" @close="() => (alertModalState = false)" />
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'
// import { ProfileModal, DutyModal, AlertModal } from '.'
import { DoctorCompanyResponse } from '@/Network/Types/Responses/CompanyResponses'
// eslint-disable-next-line global-require, @typescript-eslint/no-unused-vars
const props = defineProps<{
  // doctor?: {
  //   photo: string
  //   name: string
  //   email: string
  //   phone: string
  //   specialty: string
  //   code: string
  //   amountOfHour: number
  //   address: {
  //     cep: string
  //     uf: string
  //     city: string
  //     street: string
  //     number: string
  //   }
  //   rating: number
  //   documents: string[]
  // }
  companyDoctor: DoctorCompanyResponse
}>()

// const nullRating = computed(() => {
//   let count = props.doctor.rating;

//   while (count < 5) {
//     count += 1;
//   }

//   return count - props.doctor.rating;
// });
const alertModalState = ref(false)
const getInitalsName = (name: string) => {
  return name.substring(0, 2).toUpperCase()
}
</script>
