<template>
  <dropdown-filters @clicked="addFilter" title="Escalas" :items="listSchedules" :icon="scaleIcon" />
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue'
import DropdownFilters from '../components/DropdownFilters.vue'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { useStationStore } from '@/pinia-store/useStationStore'

export default {
  components: {
    DropdownFilters
  },
  setup() {
    const stationStore = useStationStore()
    const scheduleStore = useScheduleStore()
    const { listSchedules } = storeToRefs(useScheduleStore())

    onMounted(async () => {
      await scheduleStore.getAllSchedules()
    })

    const scaleIcon = defineAsyncComponent(() => import('@/assets/icons/scale.vue'))

    const addFilter = async (val: { title: string; value: string }) => {
      await stationStore.setScheduleFilter(+val.value, 'add')
    }

    return {
      scaleIcon,
      addFilter,
      listSchedules
    }
  }
}
</script>

<style></style>
