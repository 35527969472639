<template>
  <!-- Legend -->
  <div class="w-full flex flex-col gap-10">
    <legends-group />
    <v-row dense>
      <v-col cols="12" sm="6" md="3" lg="2">
        <router-link to="/gerenciar-escala">
          <filled-button density="compact" width="100%" text="Gerenciar escalas" />
        </router-link>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="2">
        <ghost-button
          density="compact"
          width="100%"
          @click="showCreateStationModal = true"
          text="Criar Plantão"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="2">
        <ghost-button @click="toggleExportModal" density="compact" width="100%">
          <v-icon :icon="mdiMicrosoftExcel" :size="20" class="cursor-pointer" /> Baixar Excel
        </ghost-button>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="2">
        <ghost-button
          @click="modalStore.toggleModal(EModals.EXPORT_DASHBOARD_STATIONS)"
          density="compact"
          width="100%"
        >
          <v-icon :icon="mdiDownloadOutline" :size="20" class="cursor-pointer" /> Baixar Escalas
        </ghost-button>
      </v-col>
    </v-row>
    <filters />
    <v-row v-if="isLoadingListStations" no-gutters>
      <v-col class="text-subtitle-1 text-center" cols="12"> Buscando Plantões... </v-col>
      <v-progress-linear color="primary" height="6" indeterminate rounded />
    </v-row>
    <DashboardView
      id="dashboard"
      :weekData="listStations"
      :week-table-head="listStations?.tableHead || []"
      :selectedYearDay="selectedYearDay"
      @openDutyModal="handleUpdateStation"
    />
  </div>
  <update-station-modal
    v-if="stationSelected"
    :infos="stationSelected"
    :showModal="showDutyModal"
    @closeModal="showDutyModal = false"
  />
  <create-station-modal
    :showModal="showCreateStationModal"
    @closeModal="showCreateStationModal = false"
  />
  <export-excel-modal />
  <export-stations-modal />
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineEmits } from 'vue'
import LegendsGroup from '@/components/LegendsGroup/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { onMounted, ref } from 'vue'
import DashboardView from './components/DashboardView.vue'
import UpdateStationModal from './modals/updateStation/update.station.modal.vue'
import CreateStationModal from './modals/createStation/create.station.modal.vue'
import { useStationStore } from '@/pinia-store/useStationStore'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import Filters from '@/components/Dashboard/Header/index.vue'
import { mdiDownloadOutline, mdiMicrosoftExcel } from '@mdi/js'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ExportExcelModal from './modals/exportExcel/export.excel.modal.vue'
import ExportStationsModal from './modals/exportStations/export.stations.modal.vue'
import { StationToUpdate } from './modals/createStation/helpers'
export default {
  name: 'Dashboard',
  components: {
    LegendsGroup,
    FilledButton,
    GhostButton,
    DashboardView,
    Filters,
    UpdateStationModal,
    CreateStationModal,
    ExportExcelModal,
    ExportStationsModal
  },
  setup() {
    const selectedYearDay = ref({ day: -1, month: -1 })
    const showDutyModal = ref(false)
    const showCreateStationModal = ref(false)

    const stationSelected = ref<StationToUpdate>()
    defineEmits(['closeModal'])

    const modalStore = useModalStore()
    const toggleExportModal = () => {
      modalStore.toggleModal(EModals.EXPORT_DASHBOARD_EXCEL)
    }

    const stationStore = useStationStore()
    const { listStations, isLoadingListStations } = storeToRefs(useStationStore())
    onMounted(async () => {
      stationStore.setDefaultQuery()
      await stationStore.getListStations()
    })

    const doctorStore = useDoctorStore()

    const handleUpdateStation = async (data: StationToUpdate) => {
      stationSelected.value = data
      doctorStore.doctorQuery.limit = undefined
      await doctorStore.applyFilters({ companies: [String(data.companyId)] }, false)
      showDutyModal.value = true
    }

    return {
      EModals,
      modalStore,
      selectedYearDay,
      showDutyModal,
      showCreateStationModal,
      listStations,
      stationSelected,
      handleUpdateStation,
      mdiMicrosoftExcel,
      mdiDownloadOutline,
      toggleExportModal,
      isLoadingListStations
    }
  }
}
</script>
<style scoped>
.selectedYearDayBox {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 30;
}
</style>
<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.3);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9e9eb7 rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9eb7;
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
</style>
