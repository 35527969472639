<template>
  <v-row dense class="items-center">
    <v-col cols="12" md="6">
      <v-text-field
        @update:model-value="setName"
        :model-value="name ? String(name) : undefined"
        class="w-full"
        label="Nome do Grupo"
        color="primary20"
        variant="outlined"
        density="comfortable"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        @update:model-value="setEmail"
        :model-value="email ? String(email) : undefined"
        class="w-full"
        label="Email"
        color="primary20"
        variant="outlined"
        density="comfortable"
      />
    </v-col>
    <v-col cols="12">
      <v-autocomplete
        :disabled="isUpdateGroup"
        @update:model-value="setCompany"
        :model-value="company ? String(company) : undefined"
        :items="listCompanies"
        class="w-full"
        color="primary20"
        label="* Empresa"
        variant="outlined"
        density="comfortable"
      />
    </v-col>
    <v-col cols="12">
      <v-autocomplete
        @update:model-value="setUsers"
        :model-value="users ? users : undefined"
        :items="listUsers"
        clearable
        chips
        multiple
        class="w-full"
        color="primary20"
        label="Usuários"
        variant="outlined"
        density="comfortable"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useUserStore } from '@/pinia-store/useUsersStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

defineProps(['name', 'email', 'company', 'users', 'isUpdateGroup'])
const emit = defineEmits(['update:name', 'update:email', 'update:company', 'update:users'])
const setName = (v: string) => emit('update:name', v)
const setEmail = (v: string) => emit('update:email', v)
const setCompany = (v: string) => emit('update:company', v)
const setUsers = (v: string[]) => emit('update:users', v)

const userStore = useUserStore()
const companyStore = useCompanyStore()
const { listUsers } = storeToRefs(userStore)
const { listCompanies } = storeToRefs(companyStore)

onMounted(async () => {
  await userStore.getAllUsers()
  await companyStore.getMyCompanies()
})
</script>
