import api from './api'

export default class PatchRequest {
  url: string

  body: any
  headers: Record<string, string> | undefined
  params: any | undefined
  constructor(url: string, body: any, headers?: Record<string, string>, params?: any | undefined) {
    this.url = url
    this.body = body
    this.headers = headers
    this.params = params
  }

  async run<R>() {
    return api.patch<R>(this.url, this.body, { headers: this.headers, params: this.params })
  }
}
