import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_autocomplete, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSelect', $event))),
      "model-value": $props.modelValue,
      items: $setup.items,
      disabled: !$props.scheduleId,
      variant: $props.variant,
      class: "w-full",
      color: "primary20",
      label: "Posição",
      theme: "light",
      "hide-details": ""
    }, {
      item: _withCtx(({ props, item }) => [
        _createVNode(_component_v_list_item, _mergeProps(props, {
          title: item?.raw?.title,
          subtitle: item?.raw?.subtitle
        }), null, 16, ["title", "subtitle"])
      ]),
      _: 1
    }, 8, ["model-value", "items", "disabled", "variant"])
  ]))
}