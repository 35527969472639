export type CreateCompanyByPublic = {
  name: string
  email: string
  phone: string
  password: string
  confirmPassword: string
  companyName: string
  fantasyName: string
  cnpj: string
  companyEmail: string
  companyPhone: string
  companyCep: string
  companyStreet: string
  companyNumber: string
  companyDistrict: string
  companyComplement: string
  companyUf: string
  companyCity: string
}

export type CompanyUser = {
  companyId: number
  name: string
  email: string
  phone: string
  password: string
  confirmPassword: string
  fullAccess: boolean
}

export type CompanyUserChangePassword = {
  password: string
  confirmPassword: string
}

export type CompanyDoctorInvite = {
  companyId: number
  crm: string
  crmUf: string
  autoApprove: boolean
}

export enum CompanyDoctorInviteStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED'
}

export type CompanyDoctorInviteSearchParams = {
  search?: string
  inviteStatus?: CompanyDoctorInviteStatusEnum | CompanyDoctorInviteStatusEnum[]
  names?: string
  crms?: string
  crmUFs?: string
  companies?: string
  limit?: number
  offset?: number
}
