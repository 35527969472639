<template>
  <modal-vue
    @close="handleToggleModal"
    :modalActive="modals[modalName]"
    :hasFooterBorder="true"
    isFilterModal
  >
    <template #header>
      <v-row dense>
        <v-col cols="12" class="text-left text-xl">
          <strong>Exportar dashboard</strong>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col cols="12" md="6">
        <DatePicker v-model="startDate" class="w-full" :masks="{ input: `DD/MM/YYYY` }">
          <template #default="{ inputValue, inputEvents }">
            <v-text-field
              placeholder="Dia Inicial"
              :prepend-inner-icon="mdiCalendar"
              :value="inputValue"
              v-on="inputEvents"
              density="compact"
              variant="outlined"
              class="w-full"
              color="primary20"
              :error="!!startDateError"
              :error-messages="startDateError"
            />
          </template>
        </DatePicker>
      </v-col>
      <v-col cols="12" md="6">
        <DatePicker v-model="endDate" :masks="{ input: `DD/MM/YYYY` }">
          <template #default="{ inputValue, inputEvents }">
            <v-text-field
              placeholder="Dia Final"
              :prepend-inner-icon="mdiCalendar"
              :value="inputValue"
              v-on="inputEvents"
              density="compact"
              variant="outlined"
              class="w-full"
              color="primary20"
              :error="!!endDateError"
              :error-messages="endDateError"
            />
          </template>
        </DatePicker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="font-bold text-left text-md" cols="12">Incluir no PDF:</v-col>

      <v-row dense>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="stationSemanal"
            density="compact"
            label="Plantão Semanal"
            color="primary"
            hide-details
          />
          <div class="w-[15px] h-[15px] bg-primary10 rounded"></div>
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="stationCobertura"
            density="compact"
            label="Plantão Cobertura"
            color="primary"
            hide-details
          />
          <div class="w-[15px] h-[15px] bg-tertiary rounded"></div>
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="stationExtra"
            density="compact"
            label="Plantão Extra"
            color="primary"
            hide-details
          />
          <div class="w-[15px] h-[15px] bg-feedBackSuccess rounded"></div>
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="openedStation"
            density="compact"
            label="Vaga Aberta"
            color="primary"
            hide-details
          />
          <div class="w-[15px] h-[15px] bg-alert rounded"></div>
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="confirmed"
            density="compact"
            label="Confirmado"
            color="primary"
            hide-details
          />
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="notConfirmed"
            density="compact"
            label="Não confirmado"
            color="primary"
            hide-details
          />
        </v-col>
        <v-col cols="12" md="6" class="flex items-center">
          <v-checkbox
            v-model="doctorPending"
            density="compact"
            label="Médico Pendente"
            color="primary"
            hide-details
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row dense class="md:py-5">
      <card
        v-if="openedStation"
        type="ABERTO"
        :confirmed="confirmed"
        :not-confirmed="notConfirmed"
        :doctor-pending="doctorPending"
      />
      <card
        :confirmed="confirmed"
        :not-confirmed="notConfirmed"
        :doctor-pending="doctorPending"
        v-if="stationSemanal"
        type="SEMANAL"
      />
      <card
        :confirmed="confirmed"
        :not-confirmed="notConfirmed"
        :doctor-pending="doctorPending"
        v-if="stationCobertura"
        type="COBERTURA"
      />
      <card
        :confirmed="confirmed"
        :not-confirmed="notConfirmed"
        :doctor-pending="doctorPending"
        v-if="stationExtra"
        type="EXTRA"
      />
    </v-row>
    <v-row class="text-left">
      <v-col class="text-sm text-primary" v-if="unitsFilter?.length" cols="12">
        Unidades:
        <span class="font-bold"> {{ unitsFilter?.map((s) => s.text).join(', ') }}</span>
      </v-col>
      <v-col class="text-sm text-primary" v-if="schedulesFilter?.length" cols="12">
        Escalas:
        <span class="font-bold"> {{ schedulesFilter?.map((s) => s.text).join(', ') }}</span>
      </v-col>
    </v-row>
    <template #footer>
      <v-row dense class="pt-5">
        <v-col cols="6">
          <ghost-button @click="handleToggleModal" width="100%" text="Cancelar" />
        </v-col>
        <v-col cols="6">
          <filled-button
            :loading="isLoading"
            @click="handleExport"
            text="Exportar"
            height="52px"
            width="100%"
          />
        </v-col>
      </v-row>
    </template>
  </modal-vue>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { ref } from 'vue'
import Network from '@/Network'
import { useToast } from 'vue-toastification'
import { mdiCalendar } from '@mdi/js'
import { DatePicker } from 'v-calendar'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { getDocDefinition, getWeeksBetweenDates } from './helpers'
import { DashboardType, ListFiltersRequest } from '@/Network/Types/Requests/StationRequests'
import { ListStationsResponse } from '@/Network/Types/Responses/StationResponses'
import { useStationStore } from '@/pinia-store/useStationStore'
import { computed } from 'vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { watch } from 'vue'
import { formatDateToString } from '@/utils/date'
import Card from './components/card.vue'
/* ******** Modal Props and Actions Default *********** */
const modalName = EModals.EXPORT_DASHBOARD_STATIONS
const { modals } = storeToRefs(useModalStore())
const modalStore = useModalStore()
const handleToggleModal = () => {
  modalStore.toggleModal(modalName)
}
/* **************************************************** */
const toast = useToast()
const isLoading = ref<boolean>(false)

const { listStationsQuery } = storeToRefs(useStationStore())
const healthUnitStore = useHealthUnitStore()
const scheduleStore = useScheduleStore()
const { healthUnitsMap } = storeToRefs(healthUnitStore)
const { schedulesMap } = storeToRefs(scheduleStore)
const startDateError = ref<string>('')
const startDate = ref<Date>(new Date())

watch(
  () => startDate.value,
  () => {
    if (!startDate.value) {
      startDateError.value = 'Data inicial é obrigatória'
      return
    }
    startDateError.value = ''
  }
)
const endDateError = ref<string>('')
const endDate = ref<Date>(new Date())
watch(
  () => endDate.value,
  () => {
    if (!endDate.value) {
      endDateError.value = 'Data final é obrigatória'
      return
    }
    endDateError.value = ''
  }
)

const openedStation = ref<boolean>(true)
const stationSemanal = ref<boolean>(true)
const stationCobertura = ref<boolean>(true)
const stationExtra = ref<boolean>(true)
const doctorPending = ref<boolean>(true)
const confirmed = ref<boolean>(true)
const notConfirmed = ref<boolean>(true)

const schedulesFilter = computed(() => {
  if (!listStationsQuery) return
  return listStationsQuery.value.schedules?.map((u) => ({
    id: u,
    text: schedulesMap.value.get(+u)
  }))
})

const unitsFilter = computed(() => {
  if (!listStationsQuery) return
  return listStationsQuery.value.healthUnits?.map((u) => ({
    id: u,
    text: healthUnitsMap.value.get(+u)
  }))
})

const getStations = async (): Promise<ListStationsResponse[]> => {
  const interval = getWeeksBetweenDates(startDate.value, endDate.value)
  const request: ListFiltersRequest[] = interval.map((week) => ({
    startDate: week.startDate,
    endDate: week.endDate,
    type: DashboardType.WEEK,
    doctorPending: getDoctorPending(),
    openedStation: getOpenedStation(),
    stationType: getStationType()
  }))
  return Promise.all(request.map((r) => Network.station.list(r)))
}

const getStationType = (): string[] | undefined => {
  const stationsTypes: string[] = []
  if (stationSemanal.value) stationsTypes.push('SEMANAL')
  if (stationCobertura.value) stationsTypes.push('COBERTURA')
  if (stationExtra.value) stationsTypes.push('EXTRA')

  return stationsTypes.length ? stationsTypes : undefined
}

const getDoctorPending = () => {
  if (doctorPending.value) return String(true)
  return undefined
}

const getOpenedStation = (): string | undefined => {
  const hasOtherStationType = stationSemanal.value || stationCobertura.value || stationExtra.value
  if (openedStation.value && hasOtherStationType) return undefined
  return String(openedStation.value)
}

const handleExport = async () => {
  try {
    isLoading.value = true
    const data = await getStations()
    if (!data.length) return
    const docDefinition: TDocumentDefinitions = getDocDefinition(data)
    const pdf = pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs)

    pdf.getBlob((blob) => {
      const file = new Blob([blob], { type: 'application/octet-stream' })
      const a = document.createElement('a')
      a.href = URL.createObjectURL(file)
      a.target = '_blank'
      a.download = `Plantoes de ${formatDateToString(startDate.value)} ate ${formatDateToString(
        endDate.value
      )}.pdf`
      a.click()
    })
    toast.success('PDF exportado com sucesso')
    handleToggleModal()
  } catch (error) {
    toast.error('Erro ao exportar PDF')
  } finally {
    isLoading.value = false
  }
}
</script>
<style scoped>
.card {
  @apply flex justify-center items-center w-full  h-[70px] font-bold text-xs rounded;
}
</style>
