<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]" :hasFooterBorder="true">
    <template #header>
      <div class="text-left px-[16px] flex">
        <strong>Editando {{ isSelectAll ? totalRows : ids?.length }} plantões</strong>
      </div>
    </template>
    <div class="station-form-container">
      <LocaleAndScheduleForm
        v-model:company="formStates.company"
        v-model:health-unit="formStates.healthUnit"
        v-model:schedule="formStates.schedule"
        v-model:doctor="formStates.doctor"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <PricesForm
        v-model:doctor="formStates.doctor"
        v-model:value="formStates.value"
        v-model:inCash="formStates.inCash"
        v-model:advanceRequested="formStates.advanceRequested"
        v-model:advancePaid="formStates.advancePaid"
        v-model:modified-value="formStates.modifiedValue"
        :modifiedValueEnabled="true"
        :company="formStates.company"
        :urlConviteDoctor="formStates.infos.urlConviteDoctor"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <HourAndDateForm
        v-model:date="formStates.date"
        v-model:timezone="formStates.timezone"
        v-model:startHour="formStates.startHour"
        v-model:endHour="formStates.endHour"
        :isUpdateMode="true"
      />
      <div class="w-full border-b-2 mb-4"></div>
      <StationDetailsForm
        v-model:station-type="formStates.stationType"
        v-model:our-team="formStates.ourTeam"
      />
    </div>
    <div
      v-if="false"
      class="text-left input w-full flex flex-col input mt-2 max-h-[420px] px-[8px] overflow-y-scroll"
    >
      <div class="mt-6 flex justify-between gap-5">
        <v-text-field
          v-model="formStates.value"
          class="w-full"
          prefix="R$"
          type="number"
          label="Valor acordado com o médico"
          color="primary20"
          variant="outlined"
          density="comfortable"
        />
      </div>
      <div class="flex flex-col">
        <div class="flex gap-5">
          <v-text-field
            v-model="formStates.date"
            v-maska="'##/##/####'"
            label="Dia"
            class="w-full"
            color="primary20"
            variant="outlined"
            :error="formStates.hasErrorDate"
            :error-messages="formStates.hasErrorDate ? 'Campo obrigatório' : ''"
            density="comfortable"
          />
          <v-autocomplete
            v-model="formStates.timezone"
            class="w-full"
            color="primary20"
            label="Fuso Horário"
            theme="light"
            :items="timezones"
            variant="outlined"
            :error="formStates.hasErrorDate"
            :error-messages="formStates.hasErrorDate ? 'Campo obrigatório' : ''"
            density="comfortable"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item?.raw?.title"
                :subtitle="item?.raw?.subtitle"
              />
            </template>
          </v-autocomplete>
        </div>
        <div class="flex gap-5">
          <v-text-field
            v-model="formStates.startHour"
            v-maska="'##:##'"
            label="Início"
            class="w-full"
            color="primary20"
            variant="outlined"
            :error="formStates.hasErrorDate"
            :error-messages="formStates.hasErrorDate ? 'Campo obrigatório' : ''"
            density="comfortable"
          />
          <v-text-field
            v-model="formStates.endHour"
            v-maska="'##:##'"
            label="Fim"
            class="w-full"
            color="primary20"
            variant="outlined"
            :error="formStates.hasErrorDate"
            :error-messages="formStates.hasErrorDate ? 'Campo obrigatório' : ''"
            density="comfortable"
          />
        </div>
      </div>

      <div class="flex gap-7 mt-4">
        <div>
          <span class="font-bold">Tipo de plantão</span>
          <v-radio-group density="compact" v-model="formStates.stationType" column>
            <v-radio label="Fixo" color="primary" value="SEMANAL" />
            <v-radio label="Cobertura" color="primary" value="COBERTURA" />
          </v-radio-group>
        </div>
        <div>
          <span class="font-bold">Nossa equipe</span>
          <v-radio-group density="compact" v-model="formStates.ourTeam" column>
            <v-radio label="Sim" color="primary" :value="true" />
            <v-radio label="Não" color="primary" :value="false" />
          </v-radio-group>
        </div>
      </div>
      <div class="flex gap-7 mt-4">
        <div>
          <span class="font-bold">Pagamento á vista</span>
          <v-radio-group density="compact" v-model="formStates.inCash" column>
            <v-radio label="Sim" color="primary" :value="true" />
            <v-radio label="Não" color="primary" :value="false" />
          </v-radio-group>
        </div>
        <div>
          <span class="font-bold">Antecipação Solicitada</span>
          <v-radio-group density="compact" v-model="formStates.advanceRequested" column>
            <v-radio label="Sim" color="primary" :value="true" />
            <v-radio label="Não" color="primary" :value="false" />
          </v-radio-group>
        </div>
        <div>
          <span class="font-bold">Antecipação Paga</span>
          <v-radio-group density="compact" v-model="formStates.advancePaid" column>
            <v-radio label="Sim" color="primary" :value="true" />
            <v-radio label="Não" color="primary" :value="false" />
          </v-radio-group>
        </div>
      </div>

      <!-- <DoctorChecks @saveChecks="handleUpdateChecks" :infos="formStates.histories" /> -->
    </div>

    <template #footer>
      <div class="flex gap-5 justify-end mt-4 px-3">
        <filled-button
          :loading="formStates.isLoading"
          @click="handleSubmit"
          text="Salvar alterações"
          height="52px"
          width="180px"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import { HistoryDate } from '@/Network/Types/Requests/StationRequests'
import { timezones } from '@/hooks/useCommon'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { formStates, executeSubmit, clear, executeAllRowsSubmit } from './useStation'
import { useCompanyStore } from '@/pinia-store/useCompanyStore'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import PricesForm from '@/modules/company/Dashboard/modals/createStation/components/form/prices.form.vue'
import LocaleAndScheduleForm from '@/modules/company/Dashboard/modals/createStation/components/form/locale.and.schedule.form.vue'
import StationDetailsForm from '@/modules/company/Dashboard/modals/createStation/components/form/station.details.form.vue'
import HourAndDateForm from '@/modules/company/Dashboard/modals/createStation/components/form/hours.and.date.form.vue'
import { PropType, onMounted, onBeforeUnmount } from 'vue'
import { FilterStates } from '@/components/Filters/useFilterStation'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    infos: {
      type: Array<number>
    },
    ids: {
      type: Array<number>
    },
    isSelectAll: {
      type: Boolean,
      default: false
    },
    totalRows: {
      type: Number
    },
    filters: { type: Object as PropType<FilterStates> }
  },
  components: {
    ModalVue,
    FilledButton,
    LocaleAndScheduleForm,
    PricesForm,
    StationDetailsForm,
    HourAndDateForm
  },
  setup(props, { emit }) {
    /* ******** Modal Props and Actions Default *********** */
    const modalName = EModals.UPDATE_BULK_STATION
    const { modals } = storeToRefs(useModalStore())
    const modalStore = useModalStore()
    const handleToggleModal = () => {
      clear()
      modalStore.toggleModal(modalName)
    }
    /* **************************************************** */
    /* ******** Form Submited  *********** */
    const toast = useToast()
    const handleSubmit = async () => {
      try {
        formStates.isLoading = true
        if (props.isSelectAll) {
          console.log(props.filters)
          await executeAllRowsSubmit(props.filters)
        } else await executeSubmit(props.ids)
        toast.success('Plantões editado com sucesso')
        emit('refreshData')
        clear()
        handleToggleModal()
      } catch (error: any) {
        toast.error(error?.response?.data?.message)
      } finally {
        formStates.isLoading = false
      }
    }
    /* **************************************************** */
    /* ******** Form Select List *********** */
    const doctorStore = useDoctorStore()
    const healthUnitStore = useHealthUnitStore()
    const scheduleStore = useScheduleStore()
    const { listCompanies } = storeToRefs(useCompanyStore())
    const { listDoctorsCompany } = storeToRefs(useDoctorStore())
    const { listHealthUnits } = storeToRefs(useHealthUnitStore())
    const { listSchedules } = storeToRefs(useScheduleStore())
    const companyChanged = async (v: number | null) => {
      const companyId = v ? Number(v) : null
      formStates.healthUnit = null
      formStates.doctor = null
      await healthUnitChanged(null)
      await Promise.all([
        healthUnitStore.applyFilters({ companies: [String(companyId)] }),
        doctorStore.applyFilters({ companies: [String(companyId)] })
      ])
    }
    const healthUnitChanged = async (v: number | null) => {
      const healthUnitId = v ? Number(v) : null
      formStates.schedule = null
      await scheduleStore.setSchedulesByHealthUnit(healthUnitId)
    }
    /* **************************************************** */

    const handleUpdateChecks = (h: HistoryDate[]) => (formStates.histories = h)

    // Função para fechar o modal ao pressionar ESC
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleToggleModal()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', handleKeydown)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeydown)
    })

    return {
      modalName,
      modals,
      handleToggleModal,
      formStates,
      listDoctorsCompany,
      listCompanies,
      listHealthUnits,
      listSchedules,
      companyChanged,
      healthUnitChanged,
      timezones,
      handleSubmit,
      handleUpdateChecks
    }
  }
}
</script>
