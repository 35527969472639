<template>
  <div class="w-full flex flex-col gap-5">
    <div class="flex flex-wrap justify-between items-center">
      <h1 class="title font-bold">Perfil e configurações</h1>
    </div>

    <div>
      <div class="flex items-center mb-2">
        <div class="w-12 h-12 relative max-w-max cursor-pointer" @click="() => input.click()">
          <img
            draggable="false"
            class="w-full rounded-full border-2 border-[#FAFAFA]"
            :src="`${fileValue ? fileValue : require('@/assets/icons/avatar.png').default}`"
            alt="Logo"
          />

          <div class="absolute left-[2em] bottom-[.2em]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="8" cy="8" r="8" fill="white" />
              <path
                d="M5.24951 10.6017L8.85785 6.99338L9.00613 7.14167L5.3978 10.75H5.24951V10.6017Z"
                fill="white"
                stroke="#0043CE"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <input @change="file" class="absolute w-0 h-0 hidden" type="file" ref="input" />
        </div>

        <p class="ml-4">Empresa XPTO</p>
      </div>

      <ul class="tabs w-full max-w-full flex flex-grow overflow-x-auto text-white select-none">
        <li>
          <button @click="() => (tab = 1)" :class="tab === 1 ? activeClass : inactiveClass">
            Dados
          </button>
        </li>

        <li>
          <button @click="() => (tab = 2)" :class="tab === 2 ? activeClass : inactiveClass">
            Empresas
          </button>
        </li>

        <li>
          <button @click="() => (tab = 3)" :class="tab === 3 ? activeClass : inactiveClass">
            Editar senha
          </button>
        </li>
      </ul>

      <div>
        <div v-show="tab === 1">
          <DataPanel />
        </div>
        <div v-show="tab === 2">
          <CompaniesPanel />
        </div>

        <div v-show="tab === 3">
          <PasswordPanel />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import DataPanel from './components/DataPanel/Index.vue'
import CompaniesPanel from './components/CompaniesPanel/Index.vue'
import PasswordPanel from './components/PasswordPanel/Index.vue'

const input = ref()
const tab = ref(1)
const fileValue = ref()
const toast = useToast()

const file = (event) => {
  if (FileReader && event.target.files && event.target.files.length) {
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
      if (!reader.result) return

      fileValue.value = reader.result
    }

    toast.success('Foto de perfil alterada com sucesso!')
  }
}

const activeClass = ref('inline-block py-4 text-black font-semibold border-b border-primary')
const inactiveClass = ref('inline-block py-4 text-gray-500')
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}

.tabs {
  border-bottom-width: 2px;
}

.tabs {
  gap: 60px;
}

.tabs > li > button {
  min-width: 80px;
  text-align: start;
}
</style>
